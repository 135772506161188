.infos-vcg {
  padding: 5px 10px 5px 22px;
  background-color: #f4f4f4;
}

.genalogie {
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .table_genealogie {
    width: 100%;
  }

  td {
    padding: 10px 10px 10px 20px;
    min-height: 28px;
    font-size: 14px;
    vertical-align: middle;
    width: 40%;
    position: relative;

    @media (min-width: 400px) {
      font-size: 16px;
    }

    i {
      font-style: italic;
      font-size: 12px;
    }
    .qualifSecond {
      font-size: 9px !important;
    }
    .numTravail {
      font-size: 11px;
      color: #b5b5b5;
    }

    .button-index {
      outline: none;
      text-transform: uppercase;
      background: #52ae32;
      color: #fff;
      border: 0;
      border-radius: 3px;
      text-decoration: none;
      text-shadow: none;
      padding: 1em;
      margin-top: 1em;
      font-size: 11px;
      display: block;
      position: absolute;

      @media (min-width: 770px) {
        cursor: pointer;
      }
      @media (min-width: 820px) {
        display: none;
      }
    }
  }

  @media (min-width: 820px) {
    .td_pere {
      border-bottom: solid 1px #ddd;
    }
  }

  .lineUnder {
    border-bottom: solid 1px #ddd;
  }
  .genalogie-bulle {
    &.femelle {
      padding-left: 25px;
      min-height: 17px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABe0lEQVQ4T52TPyxDURSHv9M+UTSEhYTBapcog4QIm2jEZreYsJqs2slit2lTsREMhiKxWw1ETP49RbzXK7f3td57vRLp3e6593z39zvnXCG21L47i1TnQCaAkeD4BlQZlTiSpfRxOEXqG1V6GsZLriKyAqTj4GDvotQujr8j2d5bHasBasl+cgtk+Y/EuM49kv6mhhjA/us2IuuNWwlgwIHxlAmVP+HRg2qIo1ROlro3xHhWxYjsQQcyKTipmIyZTgN58MJKXJQsiiq85kDWIhoX03Bagefgyd4ETHdC0Y1byYsqvF0AmSZAyf2VrC1l0xYAlxrwDPQ0Ac4q8BQo6EvAlE0BL3bAkANjKTj/MNzJDrj6hLtIDfRJDdBswRHQhRxtN4DrL7j3wFPxLmsLliLqa0lgIZinAxd824SovL2N+q7uRHg1dQDTRusg6WCbwHyXQRy+w3dMfn2QagDbKGt0v/YBPPoQyVfRUW5AWv1MYastf2dbjf8T+wHUC6gUmwBCzwAAAABJRU5ErkJggg==)
        left center no-repeat;
    }
    &.male {
      padding-left: 25px;
      min-height: 17px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABrklEQVQ4T6WTv0scURDHP7OnBPE3pxgVogln4LBVkHQaEgsLi5giYGchQqqYIipWojaehYggWEYMBJsUiv4B0c7ywAt3IijRSuXQJNzthLe73u2uKKKvm5k3n/nOvHlC6Mym9K1i9yryCoh74aSgPwVra6xNtv0pcm3MJrXVLrE/ggwDFWGwZ2dBl62ctTgWlwPjcwBe8hTI4C2JIbd+tXLWpIE4gOlUfg5k9LbkJxaMviiIZeaXApqYaIt8FtOzja7fIdvhPi+DD80uxAWQtZB3MpPKJxT55K/eXiH0P3U9q0fKnzwMPRO+H8P7pgIAQedlOmXvAF3mckTgdVToqIEfv127r8EFrR0pmSuoisBFvlBu1wDOgGrj6qwW3tQXKxifBXyJCd+OIX3pSPef8wBgPCasHCqn/4K3XpYLA41BsHfDARRaMIClA+UsFwRcD9Abnj+4GxiiAWyewt5FUGpPVOiqvanAGaL/GWtLYaRF2DiB/UulBGivFLrrIJFW/toBZe4zhhcpWgrDLcWlMfGFjJItTt6jeItkrEevsg/ysM/k7+zB3zm8Ife1/wNwHr4c6AUeZgAAAABJRU5ErkJggg==)
        left center no-repeat;
    }
  }

  .table_index_parents {
    padding: 0 0.75rem;
    width: 100%;
    display: none;
    overflow: auto;

    @media (min-width: 820px) {
      display: unset;
    }

    .table-index-parents {
      margin-top: 10px;
      font-size: 12px;
      min-width: 200px;

      thead {
        background: #292929;
        color: #fff;

        th {
          vertical-align: middle;
          background-color: #45474e;
          color: #fff;
          padding: 6px 9px;
          text-align: end;
          white-space: nowrap;
          font-weight: 100;
        }
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }

      tbody {
        tr {
          td {
            padding: 6px 9px;
            background-color: #f2f3fb;
            text-align: center;

            &.pp {
              background: #c8eaff;
            }
            &.pm {
              background: #f9cdf3;
            }
          }

          &.d {
            background: #f4f4f4;

            td {
              &.pp {
                background: #a2dafd;
              }
              &.pm {
                background: #fbaaf0;
              }
            }
          }
        }
        tr:last-child {
          td:first-child {
            border-radius: 0 0px 0px 5px;
          }
          td:last-child {
            border-radius: 0 0px 5px 0px;
          }
        }
      }
    }
  }
  .base_ref_parents {
    display: none;
    font-size: 9px;
    padding-top: 3px;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
    justify-content: end;
    align-items: center;
    gap: 0.25rem;

    @media (min-width: 820px) {
      display: flex;
    }

    .ibovalG {
      color: #fff;
      background-color: #b61b15;
      padding: 3px;
      border-radius: 50%;
      font-size: 9px;
      vertical-align: top;
      width: 10px;
      height: 10px;
      text-align: center;
      cursor: help;
      font-weight: bold;
      box-sizing: content-box;
      vertical-align: middle;
    }
  }
}

.modal-index {
  display: block;
  position: fixed;
  top: calc(50vh - 86px);
  left: 1vw;
  background-color: white;
  width: 98vw;
  z-index: 12;
  overflow-y: auto;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      left: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal-content {
    width: calc(100% - 24px);
    margin: 0 auto;
    overflow: auto;

    .table-index-parents {
      margin: 0 auto;
      margin-top: 10px;
      font-size: 12px;
      min-width: 200px;

      thead {
        background: #292929;
        color: #fff;

        th {
          vertical-align: middle;
          background-color: #45474e;
          color: #fff;
          padding: 6px 9px;
          text-align: end;
          white-space: nowrap;
          font-weight: 100;
        }
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }

      tbody {
        tr {
          td {
            padding: 6px 9px;
            background-color: #f2f3fb;
            text-align: center;

            &.pp {
              background: #c8eaff;
            }
            &.pm {
              background: #f9cdf3;
            }
          }

          &.d {
            background: #f4f4f4;

            td {
              &.pp {
                background: #a2dafd;
              }
              &.pm {
                background: #fbaaf0;
              }
            }
          }
        }
        tr:last-child {
          td:first-child {
            border-radius: 0 0px 0px 5px;
          }
          td:last-child {
            border-radius: 0 0px 5px 0px;
          }
        }
      }
    }
  }
  .base-ref {
    display: flex;
    font-size: 9px;
    padding-top: 3px;
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;
    justify-content: end;
    align-items: center;
    gap: 0.25rem;

    .ibovalG {
      color: #fff;
      background-color: #b61b15;
      padding: 3px;
      border-radius: 50%;
      font-size: 9px;
      vertical-align: top;
      width: 10px;
      height: 10px;
      text-align: center;
      cursor: help;
      font-weight: bold;
      box-sizing: content-box;
      vertical-align: middle;
    }
  }
}
