.wrapper_notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1), right 0.2s ease-in-out;
  z-index: 9;

  &.notif_with_side_filters {
    right: 416px;
  }
  &.bg_notif_blue_sw {
    background: #eaedf9;
  }

  &.hide {
    transform: translate3d(-100vw, 0, 0);
  }
  &.show {
    transform: translate3d(0vw, 0, 0);
  }

  .notification {
    margin: 3px 6px;
    height: 60px;
    background: #313433;
    color: #dee0df;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    border-radius: 10px;

    .div_text_update {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding-left: 1rem;

      @media (max-width: 380px) {
        padding-left: 0;
      }

      .img_update {
        height: 40px;
      }
    }

    .button-update {
      background: none;
      outline: none;
      border: none;
      color: #cee5ff;
      padding: 1rem 1rem;
      -webkit-tap-highlight-color: transparent;
      font-size: 16px;
    }
  }
}

@media (min-width: 500px) {
  .wrapper_notification {
    .notification {
      width: 450px;
      margin: 3px auto;
    }
  }
}
