.fullpage_loaderdl {
  height: calc(var(--vh) * 100);
  background-color: #eaedf9;
  .wraper_loader_dl {
    display: flex;
    flex-direction: column;

    .loader_page {
      padding-top: 2rem;
    }
    .loader_margin {
      margin-top: 20px;
    }
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      margin: auto;
      margin-top: 1rem;
      text-align: center;
    }

    .div_number_step {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.5rem;

      .number_step {
        font-size: 16px;
        // text-align: center;
        background-color: #52ae32;
        color: white;
        border-radius: 50px;
        padding: 6px 10px;
      }
    }

    .loaderDl {
      position: relative;
      width: 100%;
      height: 64px;
      text-align: center;
      font-size: 30px;
      display: flex;
      // justify-content: center;
      margin-left: calc(50% - 144px);

      .changeHidden {
        // position: absolute;
        // top: 0;
        // left: 0%;
        padding-top: 20px;
        height: 64px;
        overflow: hidden;

        .contenant {
          position: relative;
          // animation: carousel 8s ease-in-out infinite;
          -webkit-transition: all 0.25s linear;
          -moz-transition: all 0.25s linear;
          -o-transition: all 0.25s linear;
          transition: all 0.25s linear;

          &.analyse {
            transform: translateY(-20px);
          }
          &.animaux {
            transform: translateY(-40px);
          }

          .displayed_dl {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            font-size: 1rem;
            opacity: 75%;
            animation: anim 0.5s;

            &.before {
              p {
                padding-bottom: 4px;
                color: #52ae32;
                padding-left: 34px;
                opacity: 0.7;
              }
            }
            &.after {
              p {
                padding-top: 4px;
              }
            }
          }

          .active_dl {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-size: 24px;
            line-height: 24px;
            animation: anim 0.5s;
            position: relative;
          }

          .wrap_lds_ring {
            // position: absolute;
            // left: -40px;
            // margin-left: -20px;
            -webkit-transition: all 0.5s linear;
            -moz-transition: all 0.5s linear;
            -o-transition: all 0.5s linear;
            transition: all 0.5s linear;
            visibility: visible;

            &.nodisplay {
              visibility: hidden;
            }

            .lds-ring {
              // display: inline-block;
              position: relative;
              width: 18px;
              height: 18px;
            }
            .lds-ring div {
              box-sizing: border-box;
              display: block;
              position: absolute;
              width: 18px;
              height: 18px;
              border: 2px solid #fff;
              border-radius: 50%;
              animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
              border-color: blue transparent transparent transparent;
            }
            .lds-ring div:nth-child(1) {
              animation-delay: -0.45s;
            }
            .lds-ring div:nth-child(2) {
              animation-delay: -0.3s;
            }
            .lds-ring div:nth-child(3) {
              animation-delay: -0.15s;
            }
            @keyframes lds-ring {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
    .download_finished {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 24px;
      color: #52ae32;

      .text {
        position: relative;
      }
      .icon_finished {
        position: absolute;
        left: -32px;
      }
    }
    .cancel_download {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.5rem;
      p {
        padding: 1rem;
        color: #155cb7;
        text-decoration: underline;
      }
    }
    .div_dino_game {
      position: fixed;
      bottom: 0;
    }
  }
}

// @keyframes anim {
//   0%,
//   20% {
//     transform: translateY(15px);
//   }
//   100% {
//     transform: translateY(0);
//   }
// }

// @keyframes slide {
//   0% {
//     top: 0;
//   }
//   25% {
//     top: -1.2em;
//   }
//   50% {
//     top: -2.4em;
//   }
//   75% {
//     top: -3.6em;
//   }
// }

// @keyframes carousel {
//   0%,
//   20% {
//     transform: translateY(0);
//   }
//   25%,
//   45% {
//     transform: translateY(-45px);
//   }
//   50%,
//   70% {
//     transform: translateY(-90px);
//   }
//   75%,
//   95% {
//     transform: translateY(-135px);
//   }
//   100% {
//     transform: translateY(-180px);
//   }
// }
