.div_raison_sociale {
  width: 100%;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  align-items: center;

  .modal_visit_name {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }

  @media (min-width: 770px) {
    .rapport_all {
      position: absolute;
      left: 25px;
    }
  }
}

.div_activites_cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-bottom: 1rem;
  font-size: 14px;
  color: black;

  @media (min-width: 770px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1525px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .activity_card {
    width: calc(100vw - 2.5rem);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    padding: 1rem;
    background-color: #f2f3fb;
    border-radius: 5px;
    position: relative;

    @media (min-width: 770px) {
      width: 300px;
    }

    .name_activity {
      margin-top: 4px;
      margin-bottom: 20px;
    }

    .div_title_graph {
      white-space: nowrap;
      position: absolute;
      font-size: 12px;
      left: 115px;
      top: 64px;
      margin-left: 5px;
    }

    .animaux_vu_no_graph {
      padding-bottom: 80px;
    }
    .div_graph {
      display: flex;
      height: 80px;

      .div_canvas {
        display: inline-block;
        width: 100px;
      }

      .div_labels {
        display: inline-block;
        margin-top: 10px;
        margin-left: 5px;

        .div_label {
          display: flex;
          align-items: center;
          gap: 8px;

          .span_color {
            display: block;
            width: 25px;
            height: 15px;

            &.color1 {
              background: rgb(255, 99, 132);
            }
            &.color2 {
              background: rgb(54, 162, 235);
            }
            &.color3 {
              background: rgb(255, 205, 86);
            }
            &.color4 {
              background: #ffabf2;
            }
          }

          p {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 170px;
            line-height: 17px;
          }
        }
      }
    }

    .div_buttons_card {
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      position: relative;

      .btn_pdf_card {
        position: absolute;
        left: 0px;
      }

      .button_details {
        text-transform: uppercase;
        border: none;
        outline: none;
        padding: 1rem;
        margin-left: 0.5rem;
        color: white;
        -webkit-tap-highlight-color: transparent;
        border-radius: 3px;
        background-color: #44b00c;
      }
    }
  }
}

.button_pdf {
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 1rem;
  margin-left: 0;
  background: #ffebeb;
  border-radius: 3px;
  color: #cc0000;
}

.modal_ask_comparaison {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  min-width: 300px;
  max-width: 94vw;
  z-index: 12;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding: 1rem;

  .title_modal {
    margin: 0;
    font-size: 16px;
  }

  .text_modal {
    margin: 0.5rem 0;
    font-size: 14px;
  }

  .div_butons_modal {
    // position: absolute;
    // bottom: 0;
    // right: 1rem;
    display: flex;
    gap: 1rem;
    padding-top: 1rem;
    justify-content: right;

    .button_yes {
      background: #1a73e8;
      outline: 3px solid #1a73e8;
      border-radius: 5px;
      border: #ffffff 1px solid;
      // text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      padding: 0.75rem 40px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    .button_no {
      background: #ffffff;
      border-radius: 5px;
      border: #dcdee2 1px solid;
      color: #2178e9;
      padding: 0.75rem 26px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
