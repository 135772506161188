.modal_concours {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 18;
  background: white;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-size: 16px;

  .div_choices_input {
    padding-top: 0.75rem;
    max-height: calc(calc(var(--vh) * 98) - 86px);
    overflow: auto;

    p {
      margin: 0;
      padding: 0.75rem 1rem;
      min-width: 320px;
      max-width: 320px;

      @media (min-width: 400px) {
        max-width: 390px;
      }
    }
  }

  .div_button_input_choices {
    display: flex;
    justify-content: end;
    padding: 1rem;

    button {
      background: #ffffff;
      border-radius: 5px;
      border: #dcdee2 1px solid;
      color: #2178e9;
      padding: 0.75rem 26px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
