.div_modal_liste {
  position: fixed;
  top: 5vh;
  left: 12.5vw;
  z-index: 12;
  width: 75vw;
  background: white;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  max-height: 85vh;
  max-width: 300px;

  .div_header_filter {
    .titre_filtrer {
      margin: 1rem 0 0.2rem 0.75rem;
    }
  }

  .div_filters {
    max-height: 70vh;
    overflow: auto;
    user-select: none;

    p {
      margin: 0;
      padding: 0.9rem 0.75rem;
    }
  }

  .div_close_modal_text {
    display: flex;
    justify-content: end;
    padding: 1rem;

    .close_modal_text {
      background: #ffffff;
      border-radius: 5px;
      border: #dcdee2 1px solid;
      color: #2178e9;
      padding: 0.75rem 26px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}

.modal_listePeres {
  position: fixed;
  top: 0;
  z-index: 501;
  background: white;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  max-height: 85vh;
  min-width: 300px;

  .div_header_filter {
    .titre_filtrer {
      margin: 1rem 0 0.2rem 0.75rem;
    }
  }

  .div_filters {
    max-height: 70vh;
    overflow: auto;

    .div_ligne {
      display: flex;
      padding-left: 0.25rem;
      align-items: center;

      .cocheConfirmable {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
          50% 50% no-repeat;
        height: 20px;
        padding: 0 18px;
        transition: background 0.15s ease;
        width: 20px;

        &.on {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
            50% 50% no-repeat;
        }
      }

      .p_filter {
        margin: 0;
        padding: 0.9rem 0.5rem;
        font-size: 13px;
      }
    }
  }

  .div_close_modal_text {
    display: flex;
    justify-content: end;
    gap: 1rem;
    padding: 1rem;

    .btn_validate_list {
      background: #1a73e8;
      outline: 3px solid #1a73e8;
      border-radius: 5px;
      border: #ffffff 1px solid;
      color: #ffffff;
      font-weight: bold;
      padding: 0.75rem 40px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    .btn_cancel_list {
      background: #ffffff;
      border-radius: 5px;
      border: #dcdee2 1px solid;
      color: #2178e9;
      padding: 0.75rem 26px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
