.div_recap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 12;

  @media (min-width: 770px) {
    top: 5vh;
    left: 5vw;
    bottom: 5vh;
    right: 5vw;
    border-radius: 8px;
  }

  .header_recap {
    display: flex;
    border: none;
    height: 60px;
    background: #eaedf9;
    width: 100%;
    border-radius: 8px 8px 0 0;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      font-size: 22px;
      position: absolute;
      left: 0;
      top: 7px;

      &.right {
        left: unset;
        right: 0;

        @media (min-width: 770px) {
          top: 0;
          font-size: 32px;
          padding: 14px;
        }
      }

      @media (min-width: 770px) {
        top: 0;
        padding: 19px;
      }

      &.desktop {
        display: none;
      }

      @media (min-width: 770px) {
        &.desktop {
          display: block;
        }
        &.mobile {
          display: none;
        }
      }
    }

    .title_header {
      width: 100%;
      display: flex;
      padding-left: 46px;
      align-items: center;
      p {
        font-size: 1rem;
        font-weight: bold;
      }

      @media (min-width: 770px) {
        padding-left: 0;
        justify-content: center;
      }
    }
  }

  .div_body_recap {
    overflow: auto;
    height: calc(calc(var(--vh) * 100) - 60px);
    position: relative;

    @media (min-width: 770px) {
      height: calc(calc(var(--vh) * 100) - 10vh - 60px);
    }
  }
}
