.wrap_page_cheptel {
  height: calc(calc(var(--vh) * 100) - 60px);
  overflow: auto;

  // &::-webkit-scrollbar {
  //   width: 0px;
  //   // height: 9px;
  // }

  .page_cheptel {
    height: calc(calc(var(--vh) * 100) - 60px);
    // max-width: 1000px;
    margin: 0 auto;

    .banderole_concours {
      padding: 12px;
      background: #f77b71;
      color: #fff;
      border-radius: 5px;
      margin: 1rem 0.5rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      @media (min-width: 1000px) {
        width: 500px;
        margin: 1rem auto;
      }

      .declare_button {
        background: #fff;
        border: 0;
        border-radius: 3px;
        color: #f37f71;
        font-size: 1em;
        outline: none;
        padding: 1em 2em;
        text-decoration: none;
        text-shadow: none;
      }
    }

    .top_part_cheptel_page {
      @media (min-width: 1000px) {
        display: flex;
        justify-content: left;
        gap: 1rem;
        margin: 1rem;
      }
    }

    .graph_line1 {
      @media (min-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr;
        gap: 1rem;
        max-width: 100vw;
      }
    }

    .graph_line2 {
      @media (min-width: 1000px) {
        display: grid;
        grid-template-columns: 2fr 2fr 1fr;
        gap: 1rem;
        max-width: 100vw;
      }
    }

    .declarer-lanaud {
      padding-top: 1.25rem;
      text-align: center;
    }

    .button-inventory {
      margin: 20px 0px 20px 0px;
      display: flex;
      justify-content: center;
    }
  }
}
