.modal_evalim_lanaud {
  display: block;
  position: fixed;
  top: 8vh;
  left: 1vw;
  background-color: white;
  max-height: 85vh;
  width: 98vw;
  max-width: 600px;
  z-index: 12;
  color: #000;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;
    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: center;
    }
  }

  .coche_souhaitee {
    padding-left: 1rem;
    padding-bottom: 0.75rem;
  }

  .modal-content {
    max-height: calc(85vh - 166px - 40px);
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 400px) {
      overflow-x: auto;
    }

    .div-table {
      width: 96vw;
      padding-left: 1vw;
      .header-evalim {
        th {
          background-color: #45474e;
          color: #fff;
          padding: 6px 2px;
          text-align: center;
          font-weight: 100;
          position: sticky;
          top: -1px;
        }
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }
      td {
        padding: 6px 4px;
        background-color: #f2f3fb;
        vertical-align: middle;

        .coche {
          width: 20px;
          height: 20px;
          margin: auto;
          cursor: pointer;
          transition: background 0.15s ease;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
            50% 50% no-repeat;

          &.on {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
              50% 50% no-repeat;
          }
        }
      }

      tr:last-child {
        td:first-child {
          border-radius: 0 0px 0px 5px;
        }
        td:last-child {
          border-radius: 0 0px 5px 0px;
        }
      }
    }
  }
  .div-bottom {
    padding-top: 0.75rem;
    p {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      font-style: italic;
      font-size: 12px;
    }

    .div-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 1em;

      .button-validate {
        margin-top: 1rem;
        outline: none;
        font-size: 1em;
        text-transform: uppercase;
        padding: 1em 2em;
        background: #52ae32;
        color: #fff;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
        text-decoration: none;
        text-shadow: none;
        transition-property: background, color, border;
        transition-duration: 0.218s;
      }
    }
  }
}
