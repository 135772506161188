.pointagepostsevrage {
  padding-bottom: 0.5rem;
  h3 {
    border-bottom: solid 1px #ccc;
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    padding: 0.25rem;
    position: relative;
    @media (min-width: 770px) {
      cursor: pointer;
    }

    .showorhideicon {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 1.17em;
    }
  }

  table {
    width: 100%;
    margin: 0;
    font-size: 14px;
    @media (min-width: 400px) {
      font-size: 16px;
    }

    thead {
      background: #f7f7f7;

      th {
        text-align: left;
        padding: 6px 9px;
        @media (min-width: 400px) {
          padding: 8px 12px;
        }
        background: #eaedf9;
        color: #45474e;

        &.index-header {
          width: 2rem;
        }
      }
      th:last-child {
        text-align: center;
      }
    }

    tbody {
      td {
        padding: 6px 9px;
        vertical-align: middle;
        @media (min-width: 400px) {
          padding: 8px 12px;
        }
      }
      td:last-child {
        text-align: center;
      }
      tr:nth-child(even) {
        background: #f4f4f4;
      }
    }
  }

  .alert {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }
}
