.fiche_station {
  height: calc(calc(var(--vh) * 100) - 60px);
  overflow: auto;

  .photo_ani_blur {
    display: none;
    img {
      width: 1024;
      height: 768;
    }
  }

  .side_to_print {
    position: fixed;
    left: 100vw;
    display: flex;
  }

  .fiche_station_content {
    max-width: 1000px;
    margin: 0 auto;
  }
}
