.repartition {
  margin-top: 1rem;
  .piesexes {
    width: 280px;
    height: 100px;
  }
  .piefemales {
    width: 280px;
    height: 100px;
  }
}

// @media (min-width: 550px) {
//   .repartition {
//     display: flex;
//     justify-content: space-around;
//   }
// }
