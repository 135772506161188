.modal_filiation {
  display: block;
  position: fixed;
  top: 31vh;
  left: 1vw;
  background-color: white;
  width: fit-content;
  max-width: 98vw;
  z-index: 12;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      z-index: 14;
      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    .title_modal_fillitation {
      margin-left: -30px;
      @media (min-width: 400px) {
        margin-left: 0;
      }

      h2 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
    }
  }

  .modal-content {
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;
    overflow: auto;

    // div:first-child {
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   height: 56px;

    //   h2 {
    //     display: block;
    //     font-size: 1.17em;
    //     margin-block-start: 1em;
    //     margin-block-end: 1em;
    //     margin-inline-start: 0px;
    //     margin-inline-end: 0px;
    //     font-weight: bold;
    //     padding: 0 0.75rem;
    //   }
    // }

    .table_filiation {
      overflow: auto;
    }

    thead {
      th {
        vertical-align: middle;
        background-color: #45474e;
        color: #fff;
        padding: 6px 9px;
        text-align: center;
        white-space: nowrap;
        font-weight: 100;
      }
      tr:first-child {
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }
    }
    tbody {
      td {
        padding: 6px 9px;
        padding: 6px 9px;
        background-color: #f2f3fb;
        text-align: center;

        &.prelev_attente {
          background: #b61b15;
          color: #fff;
        }
      }
      tr:last-child {
        td:first-child {
          border-radius: 0 0px 0px 5px;
        }
        td:last-child {
          border-radius: 0 0px 5px 0px;
        }
      }
    }
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #000;
  }
}
