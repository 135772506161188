.animal-header {
  font-size: 14px;
  @media (min-width: 400px) {
    font-size: 16px;
  }
  display: grid;
  grid-template-areas:
    "picture"
    "infos";

  @media (min-width: 770px) {
    grid-template-areas: "infos";

    &.two_side {
      grid-template-areas: "infos picture";
    }
  }

  .area_picture {
    grid-area: picture;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    padding-top: 15px;
    display: none;
    // width: 400px;

    &.display {
      display: flex;
    }
    .img_ani_station {
      width: 100%;
      position: relative;

      img {
        width: inherit;

        &.noDisplay {
          display: none;
        }
      }
    }

    .photo_loading {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .area_infos {
    grid-area: infos;

    &.with_width {
      @media (min-width: 770px) {
        width: 470px;
        // padding-right: 1.25rem;
      }
      @media (min-width: 870px) {
        width: 500px;
      }
      @media (min-width: 970px) {
        width: 600px;
      }
    }
  }

  .titre {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    justify-content: center;
    padding-top: 15px;

    .sexe-femelle {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABe0lEQVQ4T52TPyxDURSHv9M+UTSEhYTBapcog4QIm2jEZreYsJqs2slit2lTsREMhiKxWw1ETP49RbzXK7f3td57vRLp3e6593z39zvnXCG21L47i1TnQCaAkeD4BlQZlTiSpfRxOEXqG1V6GsZLriKyAqTj4GDvotQujr8j2d5bHasBasl+cgtk+Y/EuM49kv6mhhjA/us2IuuNWwlgwIHxlAmVP+HRg2qIo1ROlro3xHhWxYjsQQcyKTipmIyZTgN58MJKXJQsiiq85kDWIhoX03Bagefgyd4ETHdC0Y1byYsqvF0AmSZAyf2VrC1l0xYAlxrwDPQ0Ac4q8BQo6EvAlE0BL3bAkANjKTj/MNzJDrj6hLtIDfRJDdBswRHQhRxtN4DrL7j3wFPxLmsLliLqa0lgIZinAxd824SovL2N+q7uRHg1dQDTRusg6WCbwHyXQRy+w3dMfn2QagDbKGt0v/YBPPoQyVfRUW5AWv1MYastf2dbjf8T+wHUC6gUmwBCzwAAAABJRU5ErkJggg==)
        left center no-repeat;
    }
    .sexe-male {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABrklEQVQ4T6WTv0scURDHP7OnBPE3pxgVogln4LBVkHQaEgsLi5giYGchQqqYIipWojaehYggWEYMBJsUiv4B0c7ywAt3IijRSuXQJNzthLe73u2uKKKvm5k3n/nOvHlC6Mym9K1i9yryCoh74aSgPwVra6xNtv0pcm3MJrXVLrE/ggwDFWGwZ2dBl62ctTgWlwPjcwBe8hTI4C2JIbd+tXLWpIE4gOlUfg5k9LbkJxaMviiIZeaXApqYaIt8FtOzja7fIdvhPi+DD80uxAWQtZB3MpPKJxT55K/eXiH0P3U9q0fKnzwMPRO+H8P7pgIAQedlOmXvAF3mckTgdVToqIEfv127r8EFrR0pmSuoisBFvlBu1wDOgGrj6qwW3tQXKxifBXyJCd+OIX3pSPef8wBgPCasHCqn/4K3XpYLA41BsHfDARRaMIClA+UsFwRcD9Abnj+4GxiiAWyewt5FUGpPVOiqvanAGaL/GWtLYaRF2DiB/UulBGivFLrrIJFW/toBZe4zhhcpWgrDLcWlMfGFjJItTt6jeItkrEevsg/ysM/k7+zB3zm8Ife1/wNwHr4c6AUeZgAAAABJRU5ErkJggg==)
        left center no-repeat;
    }
    .nom {
      font-size: 36px;
      font-weight: bold;
      padding-left: 1.5rem;
    }

    .numtravail {
      padding-left: 1.5rem;
    }

    .aboqual {
      padding-left: 0.5rem;
      font-size: 30px;
      font-weight: normal;
      color: #666666;
    }
  }

  .numtravail {
    font-size: 26px;
    // color: #ccc;
    padding-top: 0.25rem;
  }

  .infos-niassance {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 1rem;
    gap: 0.5rem;
    max-width: 450px;

    p {
      color: #333;
    }
  }

  .btn_action {
    outline: none;
    font-size: 11px;
    text-transform: uppercase;
    background: #52ae32;
    color: #fff;
    border: 0;
    border-radius: 3px;
    text-decoration: none;
    text-shadow: none;
    padding: 10px;
    width: fit-content;

    @media (min-width: 770px) {
      font-size: 13px;
    }
  }

  .table-header {
    display: flex;
    gap: 2px;
    justify-content: center;
    padding-top: 1rem;
    padding-left: 3px;
    padding-right: 3px;
    width: calc(100vw - 14px);
    max-width: 470px;
    font-size: 12px;

    @media (min-width: 400px) {
      gap: 0.5rem;
      width: calc(100vw - 11px);
      font-size: 13px;
    }
    @media (min-width: 450px) {
      gap: 1rem;
    }
    @media (min-width: 550px) {
      font-size: 16px;
    }

    thead {
      background: #292929;
      color: #fff;

      th {
        background-color: #45474e;
        color: #fff;
        padding: 6px 8px;
        text-align: end;
        white-space: nowrap;
        font-weight: 100;

        @media (min-width: 400px) {
          padding: 6px 9px;
        }
      }
      th:first-child {
        border-radius: 5px 0px 0 0;
      }
      th:last-child {
        border-radius: 0px 5px 0 0;
      }
    }

    tbody {
      td {
        padding: 6px 8px;
        background-color: #f2f3fb;

        @media (min-width: 400px) {
          padding: 6px 9px;
        }
      }

      tr:last-child {
        td:first-child {
          border-radius: 0 0px 0px 5px;
        }
        td:last-child {
          border-radius: 0 0px 5px 0px;
        }
      }
    }
  }

  .center {
    text-align: center;
  }
  .right {
    text-align: right;
    white-space: nowrap;
  }
  .alert {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
    padding-top: 15px;
  }
}

@media (min-width: 770px) {
  .div_header_animal {
    display: flex;
    justify-content: space-around;

    &.one_sided {
      display: unset;
    }
  }

  .animal-header {
    .infos-niassance {
      grid-template-columns: 2fr 3fr;
    }
  }
}
