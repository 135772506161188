.div_cadre_info {
  margin-bottom: 20px;
  background: #fff;
  width: calc(100% - 14px);
  padding: 7px;
  box-shadow: 0px 2px 3px 0px rgb(111 111 111 / 20%);

  .div_header_info {
    padding: 0px 7px;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .bulle_avatar {
      display: inline-block;
      margin: 0 10px 0 0;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 5px;
      color: #000;

      .img_avatar_info {
        height: 30px;
      }
    }
    .p_header_info {
      @media (max-width: 380px) {
        font-size: 11px;
      }
    }
    .bulle_date_info {
      color: #666666;
      font-style: italic;
      font-size: 11px;
      font-weight: 100;
    }
  }

  .bulle_content_info {
    padding: 7px;
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
  }

  .bulle_read_next {
    padding-left: 7px;
    font-size: 14px;
    line-height: 20px;
    color: #355d98;
  }
}
