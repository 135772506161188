.regles_conf {
  margin-top: 60px;
  overflow: auto;
  height: calc(calc(var(--vh) * 100) - 60px);
  width: auto;
  padding: 0 10px;
  line-height: 25px;

  h3 {
    font-size: 18px;
  }
  h4 {
    color: #28549c !important;
    margin: 0.5rem 0;
  }
  .span-arial {
    font-family: Arial;
    font-size: 16px;
  }
}
