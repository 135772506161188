.account_infos {
  display: flex;
  flex-direction: column;
  // gap: 0.25rem;
  width: 94vw;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 1rem 0;
  background-color: #f2f3fb;
  border-radius: 5px;

  .line_info {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }
  span {
    width: 46px;
    border: none;
    color: grey;
    font-size: 24px;
    user-select: none;
    padding: 3px 0;
    padding-left: 1rem;
  }

  .right_side_account_info {
    width: 100%;
    display: flex;
    align-self: center;
    padding: 0.25rem;
    text-decoration: none;
    flex-direction: column;
    padding-right: 1rem;
    font-size: 1rem;
  }

  a {
    color: #45474e;
    cursor: default;

    @media (min-width: 770px) {
      cursor: pointer;
    }
  }
}
