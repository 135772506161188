.fiche-animal {
  height: calc(calc(var(--vh) * 100) - 60px);
  max-width: 1000px;
  margin: 0 auto;

  .graph_isu_photo {
    // display: none;
    width: 856px;
    position: fixed;
    z-index: -1;
    left: -1000px;
  }

  .photo_ani_blur {
    display: none;
    img {
      width: 1024;
      height: 768;
    }
  }

  .side_to_print {
    position: fixed;
    left: 100vw;
    display: flex;
  }

  .input_file_picture {
    display: none;
  }

  .loader_dl_ani {
    position: fixed;
    top: 0;
    left: 0;
    height: calc(var(--vh) * 100);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .neverInInventory {
    color: #d40404;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    padding-bottom: 1rem;
  }

  .fast_actions {
    background-color: #eaedf9;
    margin-top: 10px;
    border-radius: 5px;
    position: relative;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;

    @media (min-width: 1000px) {
      margin-left: 0;
      margin-right: 0;
    }

    .left_side_actions {
      position: absolute;
      left: 4px;
      gap: 0.25rem;
      display: none;

      @media (min-width: 770px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .actions_buttons {
      display: flex;
      gap: 0.25rem;

      .btn_action_rapide {
        outline: none;
        font-size: 11px;
        text-transform: uppercase;
        background: #52ae32;
        color: #fff;
        border: 0;
        border-radius: 3px;
        text-decoration: none;
        text-shadow: none;
        padding: 10px;

        @media (min-width: 770px) {
          font-size: 1em;
          padding: 10px;
        }
      }
    }
  }
}

.modal_bg_dots_ani {
  position: fixed;
  top: 0;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 11;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  .modal_dots_inv {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 12;
    background: white;
    color: #45474e;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
      0 1px 5px 0 rgb(0 0 0 / 20%);

    ul {
      li {
        line-height: 40px;
        padding: 6px 10px;
        white-space: nowrap;
        font-size: 16px;
        min-width: 200px;
      }
    }
  }
  &.small {
    width: 0px;
    height: 0px;
  }
}
