.modal_statut_dl {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  max-height: calc(var(--vh) * 98);
  width: fit-content;
  z-index: 12;
  color: #000;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: center;
    }
  }
  .close_modal {
    padding: 12px;
    border-radius: 50%;
    padding-bottom: 12px;
    font-size: 32px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .div_statut {
    display: flex;
    // justify-content: center;
    align-items: center;
    min-width: 340px;
    width: 340px;
    max-width: 340px;
    padding: 3rem 0;

    .loader_default {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loaderDl {
      position: relative;
      height: 64px;
      text-align: center;
      font-size: 30px;
      display: flex;
      // justify-content: center;
      margin-left: calc(50% - 144px);

      .changeHidden {
        // position: absolute;
        // top: 0;
        // left: 0%;
        padding-top: 20px;
        height: 64px;
        overflow: hidden;

        .contenant {
          position: relative;
          // animation: carousel 8s ease-in-out infinite;
          -webkit-transition: all 0.25s linear;
          -moz-transition: all 0.25s linear;
          -o-transition: all 0.25s linear;
          transition: all 0.25s linear;

          &.analyse {
            transform: translateY(-20px);
          }
          &.animaux {
            transform: translateY(-40px);
          }

          .displayed_dl {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            font-size: 1rem;
            opacity: 75%;
            animation: anim 0.5s;

            &.before {
              p {
                padding-bottom: 4px;
                color: #52ae32;
                padding-left: 34px;
                opacity: 0.7;
              }
            }
            &.after {
              p {
                padding-top: 4px;
              }
            }
          }

          .active_dl {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-size: 24px;
            line-height: 24px;
            animation: anim 0.5s;
            position: relative;
            margin-left: 2px;
          }

          .wrap_lds_ring {
            // position: absolute;
            // left: -40px;
            // margin-left: -20px;
            -webkit-transition: all 0.5s linear;
            -moz-transition: all 0.5s linear;
            -o-transition: all 0.5s linear;
            transition: all 0.5s linear;
            visibility: visible;

            &.nodisplay {
              visibility: hidden;
            }

            .lds-ring {
              // display: inline-block;
              position: relative;
              width: 18px;
              height: 18px;
            }
            .lds-ring div {
              box-sizing: border-box;
              display: block;
              position: absolute;
              width: 18px;
              height: 18px;
              border: 2px solid #fff;
              border-radius: 50%;
              animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
              border-color: blue transparent transparent transparent;
            }
            .lds-ring div:nth-child(1) {
              animation-delay: -0.45s;
            }
            .lds-ring div:nth-child(2) {
              animation-delay: -0.3s;
            }
            .lds-ring div:nth-child(3) {
              animation-delay: -0.15s;
            }
            @keyframes lds-ring {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }
}
