.modal_game_rankings {
  display: block;
  position: fixed;
  top: 31vh;
  left: 1vw;
  background-color: white;
  padding: 1rem 0;
  padding-top: 0;
  width: 98vw;
  max-width: 440px;
  height: calc(var(--vh) * 96);
  z-index: 12;
  border-radius: 8px;

  .loading_rankings {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .close_modal {
    padding: 12px;
    border-radius: 50%;
    padding-bottom: 12px;
    font-size: 32px;
    position: absolute;
    right: 0;
    z-index: 14;

    @media (min-width: 770px) {
      cursor: pointer;
    }
  }

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-size: 16px;

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .div_rankings_wrapper {
    max-height: calc(calc(var(--vh) * 96) - 64px);
    overflow: auto;

    .line_ranking {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      font-size: 16px;
      padding: 0.5rem 1rem;

      .ranking_figure {
        font-size: 18px;

        .ranking_trophy {
          height: 20px;
        }
      }

      .ranking_user {
      }

      .ranking_score {
        font-size: 18px;
        text-align: end;
        font-weight: bold;
      }
    }
  }
}
