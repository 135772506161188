.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: calc(
    calc(var(--vh) * 98) - 142px
  ); /* change this to whatever you want */
  max-width: calc(98vw - 4px);
  width: auto;
}

.div_carrousel_no_photo .carousel .slide img {
  max-height: calc(
    calc(var(--vh) * 98) - 192px
  ); /* change this to whatever you want */
}

.carousel .control-arrow {
  background: rgba(0, 0, 0, 0.2);
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  filter: alpha(opacity = 100);
}

.carousel .control-arrow:focus,
.carousel .control-arrow {
  opacity: 1;
  filter: alpha(opacity = 100);
}

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.4);
}
