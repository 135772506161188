.asc_mat {
  margin: 1rem 0.5rem;
  //   max-width: 950px;

  @media (min-width: 1000px) {
    width: unset;
  }

  .infos-prebottom {
    text-align: center;
    padding: 0.25rem;
    font-size: 16px;
  }
  .infos-bottom {
    font-style: italic;
    text-align: center;
  }
}
