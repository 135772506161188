.mentions-legales {
  margin-top: 95px;
  width: auto;
  padding: 0 10px;
  line-height: 25px;

  h2 {
    font-size: 21px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 50px 0 30px 0;
  }
  h3 {
    color: #333;
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .span-arial {
    font-family: Arial;
    font-size: 16px;
  }
}
