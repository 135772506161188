.modal2_evalim_lanaud {
  display: block;
  position: fixed;
  top: 8vh;
  left: 1vw;
  background-color: white;
  max-height: 85vh;
  width: 98vw;
  max-width: 700px;
  z-index: 12;
  color: #000;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;
    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: center;
    }
  }

  .modal-content {
    padding: 1.25rem 0.75rem;
    padding-top: 0;

    .modal-message {
      text-align: center;
    }

    h2 {
      display: block;
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }
}
