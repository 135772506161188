.header_bar {
  background-color: #eaedf9;
  margin: auto;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 60px;
  display: flex;
  align-items: center;

  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);

  // &.push {
  //   transform: translate3d(280px, 0, 0);
  // }

  .full_width {
    width: 100%;
  }

  .div_filters {
    position: relative;
    border-radius: 50%;

    @media (min-width: 770px) {
      cursor: pointer;
    }

    .icon_nb_filters {
      position: absolute;
      left: 20px;
      top: 27px;
      z-index: 25;
      color: #fff;
      background: #ff7171;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }

  .header_icon {
    padding: 12px;
    border: none;
    color: #45474e;
    font-size: 22px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 50%;

    &.icone_mobile {
      @media (min-width: 770px) {
        display: none;
      }
    }
  }

  .text_header_inv {
    color: #45474e;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }

  .text_header_inv_cheptel {
    color: #45474e;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 770px) {
      width: calc(100vw - 138px);
    }
  }

  .div_search {
    position: relative;
    width: 100%;
    padding-right: 8px;
    background: #f5f8ff;
    border-radius: 8px;

    &.div_search_mobile {
      width: auto;
    }

    .input_search {
      border: none;
      width: inherit;
      outline: 0;
      color: black;
      height: 38px;
      font-size: 1rem;
      min-width: 20vw;
      background: inherit;
      border-radius: 3px;
      font-weight: bold;
      padding-left: 8px;

      &.set_width {
        width: calc(100vw - 82px);
      }
      &.set_width2 {
        width: calc(100vw - 128px);
      }
      &.set_width3 {
        width: calc(100vw - 174px);
      }
    }

    .clear_search {
      position: absolute;
      padding: 7px;
      right: 0;
      color: black;
      border-radius: 50%;
      opacity: 60%;
      user-select: none;

      &.clear_search_admin {
        padding: 7px;
        right: 0;
      }

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }

  .header_inv_title_only {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #45474e;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
  }

  .header_inv {
    width: 100%;

    .div_search_desktop {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 7px;
      left: 161px;
      right: 138px;

      .div_input_search_desk {
        display: flex;
        width: 55vw;
        max-width: 700px;
        background-color: #f5f8ff;
        border-radius: 8px;
        cursor: text;

        &.input_focused {
          background-color: white;
        }
        &.transition {
          transition: all 0.6s ease-out;
        }

        .search_inp_icon {
          padding: 12px;
          border: none;
          color: #45474e;
          font-size: 22px;
        }
        .input_search_desktop {
          background: inherit;
          outline: none;
          border: none;
          width: calc(55vw - 92px);
          max-width: calc(700px - 92px);
        }
        .clear_search {
          border-radius: 50%;
          width: 46px;
        }
      }

      @media (min-width: 770px) {
        display: flex;
      }
    }
  }

  .nom_ani {
    font-size: 36px;
    font-weight: bold;
    padding-top: 5px;
    color: #45474e;
    height: 41px;
  }
  .qual {
    padding-left: 0.5rem;
    font-size: 26px;
    font-weight: normal;
    color: #666666;
  }
  .num_ani {
    font-size: 26px;
    color: #ccc;
    padding-top: 0.25rem;
    display: flex;
    height: 30px;
  }
  .sexe {
    position: absolute;
    left: 17px;
    top: 50px;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: auto;
  }
  .femelle {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABe0lEQVQ4T52TPyxDURSHv9M+UTSEhYTBapcog4QIm2jEZreYsJqs2slit2lTsREMhiKxWw1ETP49RbzXK7f3td57vRLp3e6593z39zvnXCG21L47i1TnQCaAkeD4BlQZlTiSpfRxOEXqG1V6GsZLriKyAqTj4GDvotQujr8j2d5bHasBasl+cgtk+Y/EuM49kv6mhhjA/us2IuuNWwlgwIHxlAmVP+HRg2qIo1ROlro3xHhWxYjsQQcyKTipmIyZTgN58MJKXJQsiiq85kDWIhoX03Bagefgyd4ETHdC0Y1byYsqvF0AmSZAyf2VrC1l0xYAlxrwDPQ0Ac4q8BQo6EvAlE0BL3bAkANjKTj/MNzJDrj6hLtIDfRJDdBswRHQhRxtN4DrL7j3wFPxLmsLliLqa0lgIZinAxd824SovL2N+q7uRHg1dQDTRusg6WCbwHyXQRy+w3dMfn2QagDbKGt0v/YBPPoQyVfRUW5AWv1MYastf2dbjf8T+wHUC6gUmwBCzwAAAABJRU5ErkJggg==)
      left center no-repeat;
  }
  .male {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABrklEQVQ4T6WTv0scURDHP7OnBPE3pxgVogln4LBVkHQaEgsLi5giYGchQqqYIipWojaehYggWEYMBJsUiv4B0c7ywAt3IijRSuXQJNzthLe73u2uKKKvm5k3n/nOvHlC6Mym9K1i9yryCoh74aSgPwVra6xNtv0pcm3MJrXVLrE/ggwDFWGwZ2dBl62ctTgWlwPjcwBe8hTI4C2JIbd+tXLWpIE4gOlUfg5k9LbkJxaMviiIZeaXApqYaIt8FtOzja7fIdvhPi+DD80uxAWQtZB3MpPKJxT55K/eXiH0P3U9q0fKnzwMPRO+H8P7pgIAQedlOmXvAF3mckTgdVToqIEfv127r8EFrR0pmSuoisBFvlBu1wDOgGrj6qwW3tQXKxifBXyJCd+OIX3pSPef8wBgPCasHCqn/4K3XpYLA41BsHfDARRaMIClA+UsFwRcD9Abnj+4GxiiAWyewt5FUGpPVOiqvanAGaL/GWtLYaRF2DiB/UulBGivFLrrIJFW/toBZe4zhhcpWgrDLcWlMfGFjJItTt6jeItkrEevsg/ysM/k7+zB3zm8Ife1/wNwHr4c6AUeZgAAAABJRU5ErkJggg==)
      left center no-repeat;
  }

  .tooltip {
    position: absolute;
    top: 40px;
    right: 5px;
    padding: 10px;
    padding-bottom: 0;
    padding-right: 0;
    border: 0;
    margin-top: 10px;
    text-align: left;
    max-width: 90vw;
    cursor: default;
    background: #cde0fb;
    border-radius: 8px;

    p {
      margin: 4px 0;
    }

    .encoche {
      border-color: transparent transparent transparent #cde0fb;
      border-style: solid;
      border-width: 0px 0px 15px 15px;
      width: 0;
      height: 0;
      position: absolute;
      overflow: hidden;
      right: 58px;
      top: -6px;
      transform: 45deg;
      /* transform: translate3d(-00%, 50%, 0); */
      transform: rotateY(0deg) rotate(45deg);
    }

    .div_btn_tooltip {
      display: flex;
      justify-content: flex-end;
      border: none;
      width: -moz-fit-content;
      width: fit-content;
      margin-left: auto;

      .btn_tooltip {
        background: none;
        outline: none;
        border: none;
        text-transform: uppercase;
        padding: 10px;
        -webkit-tap-highlight-color: transparent;

        @media (min-width: 770px) {
          cursor: pointer;
        }
      }
    }
  }

  .wrap_lds_ring {
    // position: absolute;
    // left: -40px;
    // margin-left: -20px;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    visibility: visible;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    border-radius: 50%;

    &.nodisplay {
      visibility: hidden;
    }

    .lds-ring {
      // display: inline-block;
      position: relative;
      width: 46px;
      height: 46px;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: calc(50% - 11px);
      top: calc(50% - 11px);
      width: 22px;
      height: 22px;
      border: 2px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: blue transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.hearder_ani {
  height: 75px;
}

.overflow-lat {
  position: fixed;
  z-index: 499;
  top: 0;
  left: 0px;
  bottom: 0;
  width: 100vw;
  background: black;
  opacity: 40%;
  border: none;
  outline: none;

  &.hide {
    display: none;
  }
}

.icon_msg_unread {
  position: absolute;
  left: 20px;
  top: 27px;
  z-index: 25;
  color: white;
  background: #f7741e;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
