.div_section_title {
  position: relative;

  .cocheConfirmable {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;

    z-index: 2;

    transition: background 0.15s ease;
    padding: 19px 18px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
      50% 50% no-repeat;

    &.on {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
        50% 50% no-repeat;
    }
  }
}
.CheptelSectionTitle {
  background-color: #eaedf9;
  color: #45474e;
  padding: 10px 10px 10px 22px;
  font-size: 18px;
  border: 0;
  text-align: left;
  margin-top: 1.25rem;
  position: relative;

  &.no_margin {
    margin: 0;
  }

  &.cheptelPage {
    margin-top: 1rem;
  }

  &.case_at_left {
    padding-left: 40px;
  }

  .icon_hideshow {
    line-height: 38px;
    font-size: 1.5rem;
    position: absolute;
    right: 10px;
    top: 0;
  }
}
