.modal_register {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  height: fit-content;
  max-height: calc(var(--vh) * 96);
  width: 350px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal-content {
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;

    .successMessage {
      padding: 0 10px;
      text-align: center;
      padding-bottom: 0.75rem;
    }
    .errorMessage {
      padding: 0 10px;
      text-align: center;
      color: #cc0000;
      padding-bottom: 0.75rem;
    }
    .inputs_register {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      max-height: calc(calc(var(--vh) * 96) - 121px);
      height: 292px;
      overflow: auto;
    }

    input {
      width: 320px;
      font-size: 17px;
      padding: 15px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      border-radius: 3px;
      border: solid 1px #d2d2d2;
      width: 100%;
    }

    .erreur_input {
      border: solid 1px red;
    }

    .input_date {
      background-color: white;
      border: none;
      border-bottom: 1px solid grey;
      outline: none;
      -webkit-appearance: auto;
    }

    .div_pass {
      position: relative;

      .icon_visibility {
        position: absolute;
        top: 0;
        right: 0;
        padding: 13px;
        height: 52px;
        border-radius: 50%;

        span {
          font-size: 26px;
          height: 26px;
        }
      }
    }

    .div_btn_reinit {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 0.75rem;
    }

    .question_animal {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      font-size: 15px;

      .name_data_to_check {
        // font-size: 14px;
        font-weight: bold;
      }
      .animal_question {
        font-size: 15px;
        .numTrav {
          font-weight: bold;
        }
      }
      .errorMessageQuestion {
        padding: 0 10px;
        text-align: center;
        color: #cc0000;
        // padding-bottom: 0.75rem;
      }
    }
  }
}
