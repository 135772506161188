.inventaire_footer {
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eaedf9;
  color: #45474e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 10px;
  // width: 100%;
  z-index: 9;
  height: 62px;
  transition: all 0.2s ease-in-out;

  &.with_side_filters {
    right: 416px;
  }

  span {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ani_inventaire {
      color: #45474e;
      font-size: 12px;
    }

    @media (min-width: 580px) {
      .div_nbani_footer {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        .ani_inventaire {
          color: unset;
          font-size: unset;
        }
      }
    }
  }

  .a_date {
    @media (max-width: 385px) {
      font-size: 14px;
    }

    &.diff_nb_animals {
      @media (max-width: 580px) {
        width: 100%;
        justify-content: space-around;
        font-size: 16px;
      }
    }
  }

  input {
    border-radius: 3px;
    border: solid 1px #d2d2d2;
    padding: 5px 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    height: 41.5px;
    width: 115px;
    font-size: 1.15rem;
    margin-left: 5px;

    @media (min-width: 770px) {
      cursor: pointer;
    }

    &.hideDate {
      display: none;
    }
  }

  &.diff_bottom {
    bottom: 65px;
  }
  &.zindex9 {
    z-index: 9;
  }
  &.zindex10 {
    z-index: 10;
  }
}

.div_datepicker {
  position: fixed;
  left: calc(50vw - 157px);
  top: calc(50vh - 160px);
  z-index: 12;

  #dateInventaire {
    width: 0;
    height: 0;
    border: 0;
    padding: 0;
  }
}
