.side_panel_filters {
  position: fixed;
  z-index: 10;
  top: 60px;
  right: 0;
  width: 400px;
  transition: all 0.2s ease-in-out;

  &.notShown {
    right: -400px;
  }

  .title_panel_filter {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
    position: relative;

    .close_filters {
      padding: 8px;
      border-radius: 50%;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 14;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }

  .panel_filter {
    height: calc(calc(var(--vh) * 100) - 104px);
    overflow: auto;

    .filters_predifined {
      padding: 12px;
      background: #f77b71;
      color: #fff;
      border-radius: 6px;
      margin: 0.75rem;
      margin-bottom: 0.5rem;
      width: 376px;

      ul {
        list-style-type: disc;
        li {
          // display: inline-block;
          margin-left: 2rem;
          padding: 0.25rem;
        }
      }
    }
  }
  .panel_filter::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
  .panel_filter::-webkit-scrollbar-track {
    display: none;
    -webkit-appearance: none;
  }
  .panel_filter::-webkit-scrollbar-thumb {
    display: none;
    -webkit-appearance: none;
  }
  .panel_filter:hover::-webkit-scrollbar {
    display: unset;
    -webkit-appearance: unset;
  }
  .panel_filter:hover::-webkit-scrollbar-track {
    display: unset;
    -webkit-appearance: unset;
  }
  .panel_filter:hover::-webkit-scrollbar-thumb {
    display: unset;
    -webkit-appearance: unset;
  }
}
