.overflow_container_messagerie {
  overflow: auto;
  height: calc(calc(var(--vh) * 100) - 60px);
  margin-top: 60px;
  width: 100vw;

  .container_messagerie {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    max-width: 1000px;
    margin: 0 auto;
  }
}

.load_messages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(calc(var(--vh) * 100) - 55px);
  width: 100vw;
}
