.scoresevalim {
  padding-bottom: 0.5rem;

  h3 {
    border-bottom: solid 1px #ccc;
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    padding: 0.25rem;
    position: relative;
    @media (min-width: 770px) {
      cursor: pointer;
    }

    .showorhideicon {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 1.17em;
    }
  }

  .table-evalim {
    overflow-y: auto;
    padding: 0 3px;

    table {
      width: 100%;
      margin: 0;

      thead {
        th {
          vertical-align: middle;
          background-color: #45474e;
          color: #fff;
          padding: 6px 9px;
          text-align: center;
          white-space: nowrap;
          font-weight: 100;
        }
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }

      tbody {
        td {
          padding: 6px 9px;
          padding: 6px 9px;
          background-color: #f2f3fb;
          text-align: center;
        }
        tr:last-child {
          td:first-child {
            border-radius: 0 0px 0px 5px;
          }
          td:last-child {
            border-radius: 0 0px 5px 0px;
          }
        }
      }
    }
  }

  .baseref {
    font-size: 9px;
    padding-top: 3px;
    text-align: right;
    padding-right: 3px;
  }

  .alert {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }
}
