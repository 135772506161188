.MyCheptel {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0.5rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #f2f3fb;

  @media (min-width: 1000px) {
    width: 500px;
    margin: 0;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      font-size: 1.25rem;
    }

    .textDate {
      color: #87898e;
      margin-top: -0.25rem;
      width: 110px;

      @media (min-width: 400px) {
        width: unset;
      }
    }

    .left_bottom {
      display: flex;
      flex-direction: column;
      color: #87898e;

      .nbmale {
        padding-left: 25px;
        padding-bottom: 6px;
        padding-top: 6px;
        min-height: 29px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABrklEQVQ4T6WTv0scURDHP7OnBPE3pxgVogln4LBVkHQaEgsLi5giYGchQqqYIipWojaehYggWEYMBJsUiv4B0c7ywAt3IijRSuXQJNzthLe73u2uKKKvm5k3n/nOvHlC6Mym9K1i9yryCoh74aSgPwVra6xNtv0pcm3MJrXVLrE/ggwDFWGwZ2dBl62ctTgWlwPjcwBe8hTI4C2JIbd+tXLWpIE4gOlUfg5k9LbkJxaMviiIZeaXApqYaIt8FtOzja7fIdvhPi+DD80uxAWQtZB3MpPKJxT55K/eXiH0P3U9q0fKnzwMPRO+H8P7pgIAQedlOmXvAF3mckTgdVToqIEfv127r8EFrR0pmSuoisBFvlBu1wDOgGrj6qwW3tQXKxifBXyJCd+OIX3pSPef8wBgPCasHCqn/4K3XpYLA41BsHfDARRaMIClA+UsFwRcD9Abnj+4GxiiAWyewt5FUGpPVOiqvanAGaL/GWtLYaRF2DiB/UulBGivFLrrIJFW/toBZe4zhhcpWgrDLcWlMfGFjJItTt6jeItkrEevsg/ysM/k7+zB3zm8Ife1/wNwHr4c6AUeZgAAAABJRU5ErkJggg==)
          left center no-repeat;
      }
      .nbfemale {
        padding-left: 25px;
        padding-bottom: 6px;
        padding-top: 6px;
        min-height: 29px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABe0lEQVQ4T52TPyxDURSHv9M+UTSEhYTBapcog4QIm2jEZreYsJqs2slit2lTsREMhiKxWw1ETP49RbzXK7f3td57vRLp3e6593z39zvnXCG21L47i1TnQCaAkeD4BlQZlTiSpfRxOEXqG1V6GsZLriKyAqTj4GDvotQujr8j2d5bHasBasl+cgtk+Y/EuM49kv6mhhjA/us2IuuNWwlgwIHxlAmVP+HRg2qIo1ROlro3xHhWxYjsQQcyKTipmIyZTgN58MJKXJQsiiq85kDWIhoX03Bagefgyd4ETHdC0Y1byYsqvF0AmSZAyf2VrC1l0xYAlxrwDPQ0Ac4q8BQo6EvAlE0BL3bAkANjKTj/MNzJDrj6hLtIDfRJDdBswRHQhRxtN4DrL7j3wFPxLmsLliLqa0lgIZinAxd824SovL2N+q7uRHg1dQDTRusg6WCbwHyXQRy+w3dMfn2QagDbKGt0v/YBPPoQyVfRUW5AWv1MYastf2dbjf8T+wHUC6gUmwBCzwAAAABJRU5ErkJggg==)
          left center no-repeat;
      }

      .ivv-average {
        padding-left: 25px;
        padding-bottom: 6px;
        padding-top: 6px;
        min-height: 29px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABRUlEQVQ4T43TO0uWYRjA8d8b+gkClyYH7WCLm5OkQ1lODoGDNKWICqKTkw4trknyvhENDoGgs6AiOegHcBAPhJRTEH0DkbjgueHh5n61e3qe6/C/zg3l9wBP0Y1OnOOsZNrIhB2Ywjx+Vo5hE7BH+IivuEl+dcBDbOECy/iTwbvwAT14i7+hT4BIcxffqgjJd6j6+F6Dvcc4RiKTBJhFH2ayqEvV/2omb+EEzQT4gWFc/ycgyjlGbwCe4xNeFLrcLoMwPcRcAMYwiqgtf3cBvmAnAV5hugAYwAZe4yrTR9YHAXiGdaSO55yXWMObDLKPhdTESwzid5vNDMgE3lX6aOIRHidAjK8fk20AubhZjbFVX6Q9bOLzPZDiIoVPrPI2opyVQjl3rnIKmo5pEb+qC7zFk+qYovMxvuIx1TPPzzmyOi2V9g+8LkANJbCHWgAAAABJRU5ErkJggg==)
          left center no-repeat;
        background-size: 14px 15px;
        background-position: 1px 50%;
      }
      .nbanimalindex {
        padding-left: 25px;
        padding-top: 6px;
        white-space: nowrap;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAn0lEQVQ4T2NkoBAwlpaW/v/x4wfD5MmTGckxizE3N/c/SOPgNCA4OPj/169fGbi5uRnWrl2L1Yt4vTAEDfDw8ADHCB8fH8OqVasYSfYCLgNgBmJLJyiBSMiAsLCw/58+fUKJlUFgQGFh4f/v378z8PLyMly+fBnsTZCfxcXFGR4+fMjw69cvBjY2NgZ5eXkMPkgt3AvkZCSwAeRqhOkDAB+zsZ3rjOiZAAAAAElFTkSuQmCC)
          left center no-repeat;
      }
    }
  }

  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nbanimal {
      font-size: 5.5rem;
      font-weight: bold;
      margin-top: -1rem;
    }
    .nbanimal-text {
      color: #87898e;
      font-size: 1rem;
      padding: 1rem;
      padding-top: 0.25rem;

      @media (min-width: 1000px) {
        height: 52px;
      }
    }

    .btn_inventaire {
      outline: none;
      font-size: 1em;
      padding: 1rem 0.7rem;
      background: #44b00c;
      color: #fff;
      border: 0;
      border-radius: 3px;
      text-decoration: none;
      text-shadow: none;
      margin: 0;
      width: 100%;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }
}
