.div_title_infos_stations {
  @media (min-width: 1000px) {
    display: none;
  }
}

.infos_station {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0.5rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #f2f3fb;

  @media (min-width: 1000px) {
    width: 500px;
    margin: 0;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 1.25rem;
    }

    .left_bottom {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: #87898e;

      .div_aff_station {
        display: flex;

        .icon_aff_station {
          font-size: 15px;
          color: black;
        }

        .aff_station_p {
          padding-left: 10px;
        }
      }
    }
  }

  .right_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 152px;
    max-width: 152px;

    .nbanimal {
      font-size: 5.5rem;
      font-weight: bold;
      margin-top: -1rem;
    }
    .nbanimal-text {
      color: #87898e;
      font-size: 1rem;
      padding: 1rem 0.25rem;
      padding-top: 0.25rem;
      text-align: center;
    }

    .btn_inventaire {
      outline: none;
      font-size: 1em;
      padding: 1rem 0.7rem;
      background: #44b00c;
      color: #fff;
      border: 0;
      border-radius: 3px;
      text-decoration: none;
      text-shadow: none;
      margin: 0;
      width: 100%;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }
}
