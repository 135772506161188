.app_loading {
  height: calc(var(--vh) * 100);
  width: 100vw;
  background-color: #eaedf9;
  display: flex;
  color: #45474e;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
}

.no_install_div_app {
  background-color: #eaedf9;
  color: #45474e;
  width: 100%;
  height: calc(var(--vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;

  .pls_install {
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 30px;
  }
}

.background-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh) * 100);
  width: 100vw;
  z-index: 11;
  opacity: 40%;
  background: black;
  border: none;
  padding: 0;

  &.zindex_alert {
    z-index: 998;
  }
}
