.modal_filters {
  display: block;
  position: fixed;
  top: 31vh;
  left: 1vw;
  background-color: white;
  width: 98vw;
  max-width: 640px;
  max-height: calc(var(--vh) * 96);
  z-index: 12;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-size: 16px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      z-index: 14;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .div_filters_wrapper {
    max-height: calc(calc(var(--vh) * 96) - 136px);
    overflow: auto;

    .card_custom_filter {
      margin: 0.75rem 1rem;
      font-size: 14px;

      &:first-child {
        margin-top: 8px;
      }

      .input_type1 {
        border: none;
        border-bottom: 1px solid grey;
        outline: none;
        width: 80%;
        margin-left: 1.5rem;
        margin-bottom: 1.25rem;
        font-size: 14px;

        @media (min-width: 770px) {
          cursor: pointer;
        }
      }
    }
  }

  .div_close_modal_sort {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    margin: 1rem 0;
    // margin-left: auto;

    .close_modal_sort {
      background: none;
      outline: none;
      border: none;
      text-transform: uppercase;
      color: #fff;
      background-color: #52ae32;
      border-radius: 3px;
      padding: 1rem 1rem;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
