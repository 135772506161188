.menu {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0px;
  bottom: 0;
  width: 240px;
  background: #f7f7f7;
  color: #333333;
  overflow: auto;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);

  &.hide {
    transform: translate3d(-280px, 0, 0);
  }
  &.show {
    transform: translate3d(0vw, 0, 0);
  }

  .header_menu {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #eaedf9;
    padding: 1rem 0;
    gap: 1rem;

    .num_cheptel {
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      padding: 7px 12px;
      background: #fff;
      border-radius: 14px;
      max-width: 96%;
      text-align: center;
    }
  }

  ul {
    margin: 15px 0;
    padding: 0;
    li {
      padding: 6px 20px;
      padding-right: 0;
      user-select: none;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;

      span {
        font-size: 18px;
      }
      p {
        font-size: 13px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
    }
  }

  .icon_msg_unread_menu {
    z-index: 25;
    color: white;
    background: #f7741e;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .footer_menu {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #eaedf9;

    .div_version {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      font-size: 13px;

      @media (min-width: 770px) {
        cursor: pointer;
      }

      p {
        font-size: 13px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      .version_number {
        border-radius: 10px;
        padding: 5px 11px;
        color: white;
      }
      .red_text {
        background-color: #cc0000;
      }

      .green_text {
        background-color: #009900;
      }
    }
  }
}
