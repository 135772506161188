.modal_share_fiche_station {
  display: block;
  position: fixed;
  top: calc(50vh - 71px);
  left: calc(50vw - 128px);
  background-color: white;
  z-index: 12;
  color: #000;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;
    .folder_zip {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      left: 0;
      top: 0;

      @media (min-width: 770px) {
        display: none;
      }
    }
    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: center;
    }
  }

  .modal_content {
    max-height: calc(calc(var(--vh) * 98) - 56px);
    max-width: 98vw;
    overflow-y: auto;

    .div_image {
      img {
        max-height: calc(
          calc(var(--vh) * 98) - 142px
        ); /* change this to whatever you want */
        max-width: calc(98vw - 4px);
        width: auto;
      }
    }

    .buttons_share {
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      button {
        outline: none;
        border: 0;
        height: 50px;
        width: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ico_share {
        border-radius: 50%;

        &.share {
          background-color: #3b5998;
        }
        &.dl {
          background-color: #00bf00;
        }

        span {
          color: #fff;
          font-size: 24px;
        }
      }
    }
  }
}
