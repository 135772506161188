.mydocs {
  margin: 1rem 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (min-width: 1000px) {
    margin: 1rem;
    margin-bottom: 0;
    display: flex;

    > div {
      min-width: 230px;
      min-height: 125px;
      max-height: 125px;
    }
  }

  .colorblock {
    border-radius: 5px;
    max-width: 230px;
    @media (min-width: 770px) {
      cursor: pointer;
    }

    h3 {
      text-align: right;
      color: white;
      font-weight: 100;
      padding: 6px 9px;
    }

    .div_icon_pdf {
      text-align: center;
      .icon_pdf {
        font-size: 50px;
        padding: 5px 0 11px 0;
      }
    }

    &.fit {
      background-color: #bde5f5;

      .icon_pdf {
        color: #229cd8;
      }
    }
    &.fiva {
      background-color: #f5b8cc;

      .icon_pdf {
        color: #ea6991;
      }
    }

    .text_icon_pdf {
      color: white;
      text-align: center;
      padding: 0 0 11px 0;
      height: 24px;
    }
  }

  .doc-border {
    border-radius: 5px;
    background-color: #f2f3fb;
    padding-bottom: 0.25rem;
    max-width: 230px;

    h3 {
      padding: 6px 9px;
      background-color: #45474e;
      color: #fff;
      text-align: end;
      white-space: nowrap;
      border-radius: 5px 5px 0 0;
      font-weight: 100;
    }

    .pdf-link {
      color: #000;
      text-decoration: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABqElEQVQ4T42Tu24TQRSGvzObGGTeIigSSFCFBCEQT5LKsew2QvRpQHQU6XbtdBR5hDSIiyDiIhcIpUCISIEEAbIFSOvr7sxBsyEb20kcn2Zmzs759p//zAhDEYahDq/H59VqVcZzIwkPqCwvg7PH+1SxChubm1luHCK6vq40f0DzJ8RdGPTB9mCQQtJDU8u3Voutyirl8gr1+sYIRPTRQ+XNS2geQPcvDH6jtgPOkSj0HLzrwO5aSHmljARQi+o5RPT+PdXGcyRuo2kPSVpoEmdy/1ghtsrTGAYPQkqlEsYYQHMlsrdwVZ9932eGPqkKSAHjHMYk2TqwfTqzl2Dt8an+njSxUhnduDSPe72DmSmAOMDgW+ULoyjKxjyyLpwAXIFXH2D2Qg44KpgOcHMeUgvbO2jhIiIKTsDIFAoWL6Pbn5Db10ATePERikXvYXaG8xUszEEQQNo/PHRQBOzh/O3ncwC3vHQH77+Mmnr3BrR/QePrBMCd69BtQ2N30tM4A/Akyv8wXK04xLdQHSJ+VGq12hRt/E9x6o3398CHv41nmDhR8ykf/wFB5+O/IyfqlAAAAABJRU5ErkJggg==)
        center left no-repeat;
      padding: 10px 16px 10px 34px;
      display: block;
      background-position: 9px;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 770px) {
  .mydocs {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
