.fiche_station_header {
  font-size: 16px;
  color: #333;

  .alert {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }

  .bandereau_fiche {
    width: 100%;
    height: 48px;
    background-color: #f4f4f4;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;

    .div_logo_station_print {
      position: absolute;
      left: 0;
      top: 0;
      min-height: 48px;
      height: 48px;
      max-height: 48px;
      .logo_station_print {
        min-height: 48px;
        height: 48px;
        max-height: 48px;
      }
    }

    .rectangel_qualif {
      color: white;
      padding: 0.5rem 1rem;

      &.espoir {
        background-color: #47b248;
      }
      &.rj {
        background-color: #12a5ea;
      }
      &.rcv {
        background-color: #eaecf8;
        color: #333333;
      }
      &.nq {
        border: 1px solid #eaecf8;
        background-color: white;
        color: #333333;
      }
      &.elimine {
        background-color: #cd0005;
      }
    }
    .rectangle_pdv {
      background-color: #f6dd78;
      color: #ce7c00;
      padding: 0.5rem 1rem;
    }
  }
  .bandereau_fiche_vente {
    width: 100%;
    height: 48px;
    background-color: #f4f4f4;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    gap: 0.75rem;

    .rectangle_pdv {
      background-color: #f6dd78;
      color: #ce7c00;
      padding: 0.5rem 1rem;
    }
    .text_in_between {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text_acheteur {
      display: flex;
      // justify-content: center;
      align-items: center;
      max-width: calc(100vw - 150px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }

      @media (min-width: 1000px) {
        max-width: calc(1000px - 150px);
      }

      p {
        white-space: nowrap;
        // text-overflow: ellipsis;
        // width: inherit;
      }
    }
  }
  .card_animal {
    display: grid;
    grid-template-areas:
      "picture"
      "infos";

    @media (min-width: 770px) {
      grid-template-areas: "infos picture";

      &.one_side {
        grid-template-areas: "infos";
      }

      &.sharing_version {
        grid-template-areas:
          "picture"
          "infos";
      }
    }

    .area_picture {
      grid-area: picture;
      // width: 400px;

      &.no_display {
        display: none;
      }
      .img_ani_station {
        width: 100%;
        position: relative;

        img {
          width: inherit;

          &.noDisplay {
            display: none;
          }
        }

        .play_button {
          position: absolute;
          top: calc(50% - 40px);
          left: calc(50% - 40px);
          background-color: rgba(51, 51, 51, 50%);
          border-radius: 50%;

          &.nodisplay {
            opacity: 0;
          }

          span {
            font-size: 80px;
            color: white;
          }
        }
      }

      .photo_loading {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .area_infos {
      grid-area: infos;

      @media (min-width: 770px) {
        width: 400px;
        padding-right: 1.25rem;

        &.side_by_side {
          display: flex;
          justify-content: space-between;
          gap: 2rem;
          width: 100%;
          padding-right: unset;

          .parents_station {
            width: 100%;
          }
        }

        &.sharing_version {
          width: unset;
          display: unset;
          padding-right: unset;
        }
      }
      @media (min-width: 870px) {
        width: 500px;

        &.sharing_version {
          width: unset;
        }
      }
      @media (min-width: 970px) {
        width: 600px;

        &.sharing_version {
          width: unset;
        }
      }
      .top_part_infos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0;
        position: relative;

        .first_half {
          min-width: 240px;
          max-width: 240px;
          width: 240px;

          @media (min-width: 770px) {
            &.side_by_side {
              min-width: 380px;
              max-width: 380px;
              width: 380px;
            }
          }

          .name {
            display: flex;
            align-items: center;

            h2 {
              font-size: 36px;
              font-weight: 700;
              padding: 0.25rem 1rem;
            }
          }

          .numero_ani {
            font-size: 26px;
            padding: 0.25rem 1rem;
            padding-top: 0;
            // display: flex;
            // align-items: center;
            // gap: 0.5rem;
            position: relative;
            width: fit-content;

            .icon_go_fiche {
              position: absolute;
              // right: -40px;
              top: -7px;
              padding: 0.5rem;
              border-radius: 50%;
            }
          }
        }

        .second_half {
          // width: 100%;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          position: absolute;
          right: 1.5rem;
          top: 0;

          @media (min-width: 770px) {
            right: 0;
          }

          .tag {
            background-color: #ebedfa;
            height: 85px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 0.75rem;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: -2px;

            &.Lanaud {
              background-color: #b71916;
              color: white;
            }
            &.ABL {
              background-color: #cc9900;
              color: white;
            }
            &.Gelioc {
              background-color: #ad631a;
              color: white;
            }
            &.Gevial {
              background-color: #004202;
              color: white;
            }

            @media (min-width: 770px) {
              margin-top: 10px;
            }

            .text_tag {
              font-size: 18px;
              font-weight: bold;
            }
            .number_tag {
              font-size: 32px;
              font-weight: bold;
            }
            .name_station {
              font-size: 13px;
              margin: 5px 0;
            }
          }
        }
      }

      .bottom_part_infos {
        p {
          padding: 0.25rem 1rem;
          color: #333;
        }
      }
    }
  }

  .div_parents_fiche_station {
    .infos_vcg {
      background-color: #f4f4f4;
      padding: 5px 10px 5px 22px;
    }

    .parents_fiche_station {
      padding: 1rem 0;
      display: flex;
      justify-content: space-around;

      .genalogie_pere {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABrklEQVQ4T6WTv0scURDHP7OnBPE3pxgVogln4LBVkHQaEgsLi5giYGchQqqYIipWojaehYggWEYMBJsUiv4B0c7ywAt3IijRSuXQJNzthLe73u2uKKKvm5k3n/nOvHlC6Mym9K1i9yryCoh74aSgPwVra6xNtv0pcm3MJrXVLrE/ggwDFWGwZ2dBl62ctTgWlwPjcwBe8hTI4C2JIbd+tXLWpIE4gOlUfg5k9LbkJxaMviiIZeaXApqYaIt8FtOzja7fIdvhPi+DD80uxAWQtZB3MpPKJxT55K/eXiH0P3U9q0fKnzwMPRO+H8P7pgIAQedlOmXvAF3mckTgdVToqIEfv127r8EFrR0pmSuoisBFvlBu1wDOgGrj6qwW3tQXKxifBXyJCd+OIX3pSPef8wBgPCasHCqn/4K3XpYLA41BsHfDARRaMIClA+UsFwRcD9Abnj+4GxiiAWyewt5FUGpPVOiqvanAGaL/GWtLYaRF2DiB/UulBGivFLrrIJFW/toBZe4zhhcpWgrDLcWlMfGFjJItTt6jeItkrEevsg/ysM/k7+zB3zm8Ife1/wNwHr4c6AUeZgAAAABJRU5ErkJggg==)
          0 no-repeat;
        min-height: 17px;
        padding-left: 20px;
      }
      .genalogie_mere {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABe0lEQVQ4T52TPyxDURSHv9M+UTSEhYTBapcog4QIm2jEZreYsJqs2slit2lTsREMhiKxWw1ETP49RbzXK7f3td57vRLp3e6593z39zvnXCG21L47i1TnQCaAkeD4BlQZlTiSpfRxOEXqG1V6GsZLriKyAqTj4GDvotQujr8j2d5bHasBasl+cgtk+Y/EuM49kv6mhhjA/us2IuuNWwlgwIHxlAmVP+HRg2qIo1ROlro3xHhWxYjsQQcyKTipmIyZTgN58MJKXJQsiiq85kDWIhoX03Bagefgyd4ETHdC0Y1byYsqvF0AmSZAyf2VrC1l0xYAlxrwDPQ0Ac4q8BQo6EvAlE0BL3bAkANjKTj/MNzJDrj6hLtIDfRJDdBswRHQhRxtN4DrL7j3wFPxLmsLliLqa0lgIZinAxd824SovL2N+q7uRHg1dQDTRusg6WCbwHyXQRy+w3dMfn2QagDbKGt0v/YBPPoQyVfRUW5AWv1MYastf2dbjf8T+wHUC6gUmwBCzwAAAABJRU5ErkJggg==)
          0 no-repeat;
        min-height: 17px;
        padding-left: 20px;
      }

      .numero {
        color: #b5b5b5;
        font-size: 11px;
      }

      i {
        font-size: 12px;
        font-style: italic;
      }
    }
  }
}
