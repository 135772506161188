.page {
  margin-top: 60px;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  margin-bottom: 0.5rem;
  overflow: auto;

  // &.push {
  //   transform: translate3d(280px, 0, 0);
  // }

  &.load_dl {
    margin-top: 0;
    background-color: #eaedf9;
    height: calc(var(--vh) * 100);
    overflow: hidden;
    margin-bottom: 0;
  }
  &.no_overflow {
    overflow: hidden;
  }

  .erreur-fiche-animal {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #cc0000;
    padding: 50px 0;
  }

  .loader_page {
    padding-top: 2rem;
  }

  .restart_dl {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
  }
}

.page_ani {
  margin-top: 60px;
}

.hide-modal {
  display: none !important;
}

.close-modal {
  background: none;
  border: 0;
  font-size: 2rem;
  position: absolute;
  right: 0;
  color: grey;
  width: 50px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAYAAABWk2cPAAAAw0lEQVRIS+2WYQqAIAyF68p6gOoAeuZYIEio7z0pC8o/QW7va3ObzdMDa36AOf3QW7NeTW8IYTWy9/54Kgv5FqHm5JxbDBRj3BQw4wuhCjgHtvya6U3RMmAWaFrNlmGFWLtUF7BPkSDaLxUghJpTTbgHCNObf2UJoJx5rkVFmhzO4PRebSsJ2kq1Mjwk6PBIh5/p8OpFbYH25T5lBVk7OJFUIcX+PbcMcyfW+pLxfc+fgzJdemylidQDkFvmKshZ5zuR7tsBAC2QoFzYAAAAAElFTkSuQmCC)
    center center no-repeat;
  border-radius: 50%;
}
.close-modal2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAYAAABWk2cPAAAAxklEQVRIS+2W0Q2AIAxEdSzmYgB1AOZiLVMTEmKAuyOKJsqPibT3bGmL8/TAmh9gTj/01qxX0xtCWI3svT+eykK+Rag5xRgXAznnNgXM+EKoAs6BLb9melO0DJgFmlazZVgh1i7VBexTJIj2SwUIoeZUE+4BwvTmX1kCKGeea1GRJoczOL1X20qCtlKtDA8JOjzS4Wc6vHpRW6B9uU9ZQdYOTiRVSLF/zy3D3Im1vmR83/PnoEyXHltpIvUA5Ja5CnLW+U6kO02rAC0rBoqbAAAAAElFTkSuQmCC)
    center center no-repeat;
}
