.biggreenbutton {
  outline: none;
  font-size: 1em;
  // text-transform: uppercase;
  padding: 1em 2em;
  background: #44b00c;
  color: #fff;
  border: 0;
  border-radius: 3px;
  text-decoration: none;
  text-shadow: none;
  // margin: 20px 0 10px 0;

  @media (min-width: 770px) {
    cursor: pointer;
  }
}
