.perf_station {
  .graph_for_ref {
    position: fixed;
    left: 100vw;

    canvas {
      height: 290px;
    }
  }

  .graph_gmq {
    padding-top: 1rem;
    margin: 0 auto;
    width: 95%;

    @media (min-width: 770px) {
      width: 75%;
      max-width: 750px;

      canvas {
        width: inherit;
        max-width: inherit;
      }
    }

    .line_billes {
      display: flex;
      width: fit-content;
      gap: 1rem;
      margin: 0 auto;

      .bille_gmq {
        width: fit-content;
        padding: 0.5rem 1rem;
        background-color: #d3e8f7;
        border-radius: 1rem;
      }
      .bille_serie {
        width: fit-content;
        padding: 0.5rem 1rem;
        background-color: rgb(151, 149, 149, 50%);
        border-radius: 1rem;
      }
    }
    .legend_gmq_unofficial {
      display: flex;
      width: fit-content;
      font-style: italic;
      font-size: 12px;
      gap: 1rem;
      margin: 0 auto;
      margin-top: 8px;
    }
  }

  .alert {
    padding: 0.75rem;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }
}
