.myinfoshbl {
  width: inherit;
  margin: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  @media (min-width: 1000px) {
    margin: 1rem;
  }

  .nowrap {
    white-space: nowrap;
  }

  .visites {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    grid-template-columns: 1fr;

    @media (min-width: 770px) {
      display: grid;
      grid-template-columns: 2fr 3fr;
    }
    @media (min-width: 1000px) {
      display: flex;
      flex-direction: unset;
    }

    .visites_a_venir {
      @media (min-width: 770px) {
        display: unset;
      }
      @media (min-width: 1000px) {
        width: 550px;
      }

      .visite_future {
        background-color: #f2f3fb;
        height: 120px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 10px;

        @media (min-width: 770px) {
          height: 196px;
        }

        .title {
          font-size: 1rem;
          font-weight: bold;
          padding-bottom: 10px;
        }
        .infos_visite {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          height: 100%;

          @media (min-width: 770px) {
            justify-content: center;
          }

          .photo_inspecteur {
            width: 60px;
            border-radius: 50%;
          }

          .text_next_visite {
            .bold {
              font-weight: bold;
            }
          }
        }
        .no_visit_planned {
          height: 100%;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }

    .visites_past {
      @media (min-width: 1000px) {
        width: 100%;
      }
      .visites_passees {
        background-color: #f2f3fb;
        height: 196px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        max-width: 95vw;
        padding: 10px;
        padding-bottom: 0;

        @media (min-width: 770px) {
          max-width: 564px;
        }
        @media (min-width: 1000px) {
          width: 100%;
          max-width: calc(100vw - 350px);
        }

        .title {
          font-size: 1rem;
          font-weight: bold;
          padding-bottom: 10px;
        }
        .content_visites_passees {
          max-width: 95vw;
          height: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          display: flex;
          align-items: center;
          padding: 4px 0;
          padding-bottom: 14px;

          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }

          .card_visite {
            min-width: 220px;
            background-color: white;
            border-radius: 5px;
            font-size: 13px;
            margin: 4px;

            &:nth-child(1) {
              h3 {
                background-color: #bbecd0;
                color: #4bb073;
              }
            }
            &:nth-child(2) {
              h3 {
                background-color: #bde5f5;
                color: #229cd8;
              }
            }
            &:nth-child(3) {
              h3 {
                background-color: #f0ddbd;
                color: #ed9808;
              }
            }
            &:nth-child(4) {
              h3 {
                background-color: #f5b8cc;
                color: #ea6991;
              }
            }
            &:nth-child(5) {
              h3 {
                background-color: #d3d3fa;
                color: #8270ff;
              }
            }
            h3 {
              background-color: #45474e;
              color: #fff;
              text-align: end;
              white-space: nowrap;
              border-radius: 5px 5px 0 0;
              font-weight: 100;
              width: 100%;
            }

            p {
              padding: 20px 10px;
              text-align: center;
            }
          }
          .card_plus {
            background-color: white;
            min-width: 110px;
            height: 78px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 48px;
            margin: 4px;
          }

          .no_visit_passee {
            height: 100%;
            font-size: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
          }
        }
        .div_btn_visits {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px;
          padding-top: 0;
        }
      }
    }
  }

  h3 {
    padding: 6px 9px;
    text-align: center;
    width: 100%;

    @media (max-width: 360px) {
      padding-left: 5px;
    }
  }

  .rapports_pdfs {
    background-color: #f2f3fb;
    padding: 10px;
    border-radius: 5px;

    .title {
      font-size: 1rem;
      font-weight: bold;
      padding-bottom: 10px;
    }

    .pdfs_hbl {
      max-width: 95vw;
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 4px 0;

      &.isMobile {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }

      .card_pdf {
        background-color: #fff;
        border-radius: 5px;
        min-width: 270px;

        .title_pdf {
          background-color: #45474e;
          color: #fff;
          padding: 6px 9px;
          text-align: left;
          width: 100%;
          white-space: nowrap;
          border-radius: 5px 5px 0 0;
          font-weight: 100;

          &.red {
            background: #f5b8cc;
            color: #ea6991;
          }
          &.purple {
            background: #d3d3fa;
            color: #8270ff;
          }
          &.yellow {
            background: #f0ddbd;
            color: #ed9808;
          }
          &.green {
            background: #bbecd0;
            color: #4bb073;
          }
          &.blue {
            background: #bde5f5;
            color: #229cd8;
          }
        }

        .card_pdf_infos {
          padding: 1rem;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .card_pdf_nb_editions {
            display: flex;
            flex-direction: column;
            align-items: center;

            .nb_editions_pdf {
              font-size: 2rem;

              &.red {
                color: #ea6991;
              }
              &.purple {
                color: #8270ff;
              }
              &.yellow {
                color: #ed9808;
              }
              &.green {
                color: #4bb073;
              }
              &.blue {
                color: #229cd8;
              }
            }
          }

          .card_pdf_last_editions {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.25rem;

            .date_last_edition {
              // padding-top: 0.25rem;
              font-size: 22px;
            }
          }
        }

        .no_editions {
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 77px;
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }

  .infos-hbl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;

    @media (min-width: 450px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 560px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 770px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1120px) {
      display: flex;
    }

    .infos-hbl-value {
      text-align: center;
      font-size: 50px;
      padding: 0 0 10px 0;
    }
    .box_color {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      min-width: 123px;
      max-width: 190px;
      height: 100px;

      @media (min-width: 1120px) {
        // width: calc((100vw - 64px - 0.8rem * 7) / 8);
        width: 150px;
      }

      h3 {
        text-align: right;

        font-weight: 100;
        p {
          color: white;
        }
      }

      &.red {
        background: #f5b8cc;

        h3 {
          color: #ea6991;
        }
        .infos-hbl-value {
          color: #ea6991;
        }
      }
      &.purple {
        background: #d3d3fa;

        h3 {
          color: #8270ff;
        }
        .infos-hbl-value {
          color: #8270ff;
        }
      }
      &.blue {
        background: #bde5f5;

        h3 {
          color: #229cd8;
        }
        .infos-hbl-value {
          color: #229cd8;
        }
      }
      &.yellow {
        background: #f0ddbd;

        h3 {
          color: #ed9808;
        }
        .infos-hbl-value {
          color: #ed9808;
        }
      }
      &.green {
        background: #bbecd0;

        h3 {
          color: #4bb073;
        }
        .infos-hbl-value {
          color: #4bb073;
        }
      }
    }
  }

  .docs-hbl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media (min-width: 540px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 770px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1000px) {
      display: flex;

      > div {
        width: 200px;
      }
    }

    .pdf-link {
      color: #000;
      text-decoration: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABqElEQVQ4T42Tu24TQRSGvzObGGTeIigSSFCFBCEQT5LKsew2QvRpQHQU6XbtdBR5hDSIiyDiIhcIpUCISIEEAbIFSOvr7sxBsyEb20kcn2Zmzs759p//zAhDEYahDq/H59VqVcZzIwkPqCwvg7PH+1SxChubm1luHCK6vq40f0DzJ8RdGPTB9mCQQtJDU8u3Voutyirl8gr1+sYIRPTRQ+XNS2geQPcvDH6jtgPOkSj0HLzrwO5aSHmljARQi+o5RPT+PdXGcyRuo2kPSVpoEmdy/1ghtsrTGAYPQkqlEsYYQHMlsrdwVZ9932eGPqkKSAHjHMYk2TqwfTqzl2Dt8an+njSxUhnduDSPe72DmSmAOMDgW+ULoyjKxjyyLpwAXIFXH2D2Qg44KpgOcHMeUgvbO2jhIiIKTsDIFAoWL6Pbn5Db10ATePERikXvYXaG8xUszEEQQNo/PHRQBOzh/O3ncwC3vHQH77+Mmnr3BrR/QePrBMCd69BtQ2N30tM4A/Akyv8wXK04xLdQHSJ+VGq12hRt/E9x6o3398CHv41nmDhR8ykf/wFB5+O/IyfqlAAAAABJRU5ErkJggg==)
        center left no-repeat;
      padding: 10px 16px 10px 34px;
      display: block;
      background-position: 9px;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    .eye-link {
      color: #000;
      text-decoration: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABsElEQVQ4T6VTPWtUQRQ9Z96KgoRgIYIELBQC0comoIWIJKKFYGkT2Df3rYVoK9jrD5AU8ubOgn/AwkoikVQSVLQxIdiYUlOoSGBF3b3y1rfL8Fxs9nb3zLnnfg4xpXHKeEwUaLfbC1mWLQOYrxKY2QcAz2OM75sJ/xEoiuKumd0BcLxB/gRgVVXvp/hYwHt/iuQzACdHBDN7StIAXAP+Vmtmu1mWXSnLcqfyh2Ce5/POuSFQ20tVPZ9mEpENABdGGMkzIYQt5nk+45zbA3CoftxX1ZmiKE6bWRVEM1uKMb4TkW8AZmvez16vd5Te+02Si0m2qKoiIvsADtf4b1U9ICKrAG4l3NcUkV0AJxKwVNWbIvIDwMEaN1V1IvIQwO2kjW3WpY7XQ/J7CGG20+mcHQwGL1hN0eyyqm6KyBcAR0YCrVbr2HCI3vucZEyq2FDVi40hrgFYSja0GGN8NV6jiKwAeJwE/QLwxMyM5PWknc/OuatlWb4dr3EUJCJzJB+Y2Q0ArcYhfSX5KIRwb+IhpaD3/hLJcwAqweqQPvb7/fVut/umITr5LzRJ//On/o1/AEIBm3gaNjrTAAAAAElFTkSuQmCC)
        center left no-repeat;
      padding: 10px 16px 10px 34px;
      padding-right: 0;
      display: block;
      background-position: 9px;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    h3 {
      background-color: #45474e;
      color: #fff;
      text-align: end;
      white-space: nowrap;
      border-radius: 5px 5px 0 0;
      font-weight: 100;
    }

    .docs {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      background-color: #f2f3fb;
      max-width: 230px;

      .list {
        height: 118px;
        overflow: auto;
      }
    }
  }
  .italic {
    font-style: italic;
    padding: 6px;
  }
}

.modal_liste_pdfs {
  position: fixed;
  top: 0;
  z-index: 501;
  background: white;
  box-shadow: 3px 5px 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-height: 85vh;
  min-width: 300px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;
    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: center;
    }
  }

  .div_pdfs {
    max-height: calc(85vh - 56px);
    overflow: auto;

    .div_ligne {
      display: flex;
      align-items: center;

      .span_pdf {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABqElEQVQ4T42Tu24TQRSGvzObGGTeIigSSFCFBCEQT5LKsew2QvRpQHQU6XbtdBR5hDSIiyDiIhcIpUCISIEEAbIFSOvr7sxBsyEb20kcn2Zmzs759p//zAhDEYahDq/H59VqVcZzIwkPqCwvg7PH+1SxChubm1luHCK6vq40f0DzJ8RdGPTB9mCQQtJDU8u3Voutyirl8gr1+sYIRPTRQ+XNS2geQPcvDH6jtgPOkSj0HLzrwO5aSHmljARQi+o5RPT+PdXGcyRuo2kPSVpoEmdy/1ghtsrTGAYPQkqlEsYYQHMlsrdwVZ9932eGPqkKSAHjHMYk2TqwfTqzl2Dt8an+njSxUhnduDSPe72DmSmAOMDgW+ULoyjKxjyyLpwAXIFXH2D2Qg44KpgOcHMeUgvbO2jhIiIKTsDIFAoWL6Pbn5Db10ATePERikXvYXaG8xUszEEQQNo/PHRQBOzh/O3ncwC3vHQH77+Mmnr3BrR/QePrBMCd69BtQ2N30tM4A/Akyv8wXK04xLdQHSJ+VGq12hRt/E9x6o3398CHv41nmDhR8ykf/wFB5+O/IyfqlAAAAABJRU5ErkJggg==)
          center left no-repeat;
        margin-left: 0.9rem;
        width: 18px;
        height: 42px;
      }

      .p_pdf {
        margin: 0;
        padding: 0.9rem 0.5rem;
        text-decoration: none;
        font-size: 16px;
      }
    }
  }
}
