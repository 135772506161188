.delete_account {
  display: flex;
  height: calc(var(--vh) * 100);
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: #eaedf9;

  .left_arrow_back {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 7px 0;

    span {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border: none;
      border-radius: 50%;
      color: #45474e;
      font-size: 22px;
      padding: 12px;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  .logo_login {
    position: absolute;
  }

  .mustBeLogged {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 80px;
    color: #cc0000;
    padding: 10px 10px;
  }

  .formlogin {
    width: 80%;
    max-width: 330px;

    .loginTitle {
      text-align: center;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 1rem;
    }

    .errorMessage {
      padding: 10px;
      text-align: center;
      color: #cc0000;
    }

    .connectInput {
      width: 100%;
      font-size: 17px;
      padding: 15px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;

      &.first {
        border-radius: 3px 3px 0 0;
        border-top: solid 1px #d2d2d2;
        border-left: solid 1px #d2d2d2;
        border-right: solid 1px #d2d2d2;
        border-bottom: 0;
        margin-bottom: 0px;
      }

      &.second {
        border-radius: 0 0 3px 3px;
        border: solid 1px #d2d2d2;
      }
    }

    .div_pass {
      position: relative;

      .icon_visibility {
        position: absolute;
        top: 0;
        right: 0;
        padding: 13px;
        height: 52px;
        border-radius: 50%;

        span {
          font-size: 26px;
          height: 26px;
        }
      }
    }

    .line_links_login {
      display: flex;
      justify-content: space-between;

      .login_links {
        color: #000;
        text-decoration: underline;
        display: flex;
        margin-top: 0.25rem;
        padding: 1rem 0.25rem;
        width: 100%;

        &.pass_forgotten {
          justify-content: flex-end;
        }
      }
    }

    .deleteButton {
      width: 100%;
      outline: none;
      font-size: 1em;
      text-transform: uppercase;
      padding: 1em 1.5em;
      background: red;
      color: #fff;
      border: 0;
      border-radius: 3px;
      text-decoration: none;
      text-shadow: none;
      margin-top: 0.25rem;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }
}
