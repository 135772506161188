.fillingbar {
  width: inherit;
  border: solid 1px #ccc;
  height: 18px;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;

  .regle {
    position: relative;
    top: -15px;
    height: 10px;
    width: 100%;
    font-size: 8px;
    cursor: default;

    .point {
      font-size: 9px;
      padding-top: 2px;
      position: absolute;
    }
  }

  .filler {
    height: 17px;
    width: 0;
    background: #b61b15;
    transition: width 0.5s;

    &.green {
      background: #2ecc71;
    }
    &.blue {
      background: #3498db;
    }
  }
}
