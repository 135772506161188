.evalim_station {
  .div_table {
    padding: 0.75rem;
    width: 100%;

    table {
      width: 100%;

      thead {
        th {
          vertical-align: middle;
          background-color: #45474e;
          color: #fff;
          padding: 6px 9px;
          text-align: center;
          white-space: nowrap;
          font-weight: 100;
        }
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }
      tbody {
        td {
          padding: 6px 9px;
          padding: 6px 9px;
          background-color: #f2f3fb;
          text-align: center;
        }
        tr:last-child {
          td:first-child {
            border-radius: 0 0px 0px 5px;
          }
          td:last-child {
            border-radius: 0 0px 5px 0px;
          }
        }
      }
    }

    .result_evalim_baseref {
      text-align: end;
    }
  }

  .alert {
    padding: 0.75rem;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }
}
