.performances {
  margin-top: 0.5rem;
  .alert {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }

  .first_td_p_width {
    width: 152px;
    line-height: 14px;
    @media (min-width: 400px) {
      width: unset;
      white-space: nowrap;
    }
  }
  .full_width {
    width: 100%;
  }
}
@media (min-width: 770px) {
  .wrap_table_indexes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
