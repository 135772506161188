.modal_erreursconf {
  display: block;
  position: fixed;
  top: calc(50vh - 106px);
  left: 1vw;
  background-color: white;
  width: 98vw;
  max-width: 420px;
  z-index: 12;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal-content {
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;

    ul {
      list-style: unset;
      list-style-type: disc;
      li {
        list-style-type: disc;
      }
    }
    .erreur {
      padding: 0.25rem 0.5rem;
      font-size: 1rem;
      margin-left: 2.5rem;
    }
    .erreur:last-child {
      padding-bottom: 0.75rem;
    }
  }
}
