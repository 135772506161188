@font-face {
  font-family: "roboto";
  src: url("./assets/woff/Roboto-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "roboto";
  src: url("./assets/woff/Roboto-Bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

:root {
  --vh: 1vh; /* Default for older browsers */
}

@supports (height: 100dvh) {
  :root {
    --vh: 1dvh; /* Overrides if dvh is supported */
  }
}

body {
  margin: 0;
  /* font-family: sans-serif; */
  font-family: "roboto", Arial;
  color: #45474e;
  user-select: none;
  overflow: hidden;
  touch-action: none;
  scroll-behavior: none;
}

*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 450, "GRAD" 0, "opsz" 48;
  user-select: none;
}
.material-symbols-outlined_200 {
  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" 0, "opsz" 48;
}
.material-symbols-outlined-filled {
  font-variation-settings: "FILL" 1, "wght" 450, "GRAD" 0, "opsz" 48;
}
.material-symbols-outlined_even_more_weight {
  font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 48;
}
.material-symbols-outlined_even_more_weight2 {
  font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 30;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:active {
  background-color: rgba(255, 255, 255, 0.25) !important;
  transition: background 0s;
}
.ripple2 {
  background-position: center;
  transition: background 0.8s;
}
.ripple2:active {
  background-color: rgba(18, 65, 134, 0.25) !important;
  transition: background 0s;
}
.ripple3 {
  background-position: center;
  transition: background 0.8s;
}
.ripple3:active {
  background-color: #e1e1e1 !important;
  transition: background 0s;
}
.ripple4 {
  background-position: center;
  transition: background 0.8s;
}
.ripple4:active {
  background-color: rgba(204, 0, 0, 0.25) !important;
  transition: background 0s;
}
.ripple5 {
  background-position: center;
  transition: background 0.8s;
}
.ripple5:active {
  background-color: rgba(234, 237, 249, 0.5) !important;
  transition: background 0s;
}
.ripple6 {
  background-position: center;
  transition: background 0.8s;
}
.ripple6:active {
  background-color: rgba(68, 176, 12, 0.5) !important;
  transition: background 0s;
}
.ripple7 {
  background-position: center;
  transition: background 0.8s;
}
.ripple7:active {
  background-color: rgba(245, 184, 204, 0.5) !important;
  transition: background 0s;
}
.ripple8 {
  background-position: center;
  transition: background 0.8s;
}
.ripple8:active {
  background-color: rgba(211, 211, 250, 0.5) !important;
  transition: background 0s;
}
.ripple9 {
  background-position: center;
  transition: background 0.8s;
}
.ripple9:active {
  background-color: rgba(240, 221, 189, 0.5) !important;
  transition: background 0s;
}
.ripple10 {
  background-position: center;
  transition: background 0.8s;
}
.ripple10:active {
  background-color: rgba(187, 236, 208, 0.5) !important;
  transition: background 0s;
}
.ripple11 {
  background-position: center;
  transition: background 0.8s;
}
.ripple11:active {
  background-color: rgba(189, 229, 245, 0.5) !important;
  transition: background 0s;
}
.ripple12 {
  background-position: center;
  transition: background 0.8s;
}
.ripple12:active {
  background-color: rgba(204, 0, 0, 0.5) !important;
  transition: background 0s;
}

.wrapper_error {
  background-color: #eaedf9;
  height: calc(var(--vh) * 100);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div_error_handled {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.fix_scrollbar_ios {
  -webkit-transform: translateZ(0);
}

@media (min-width: 770px) {
  .pointer {
    cursor: pointer;
  }

  body {
    user-select: unset;
  }
}
