.graphivv {
  margin: 1rem 0.5rem;

  @media (min-width: 1000px) {
    width: unset;
  }

  .infos-bottom {
    font-style: italic;
    text-align: center;
  }
}
