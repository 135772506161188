.wrap_asc_pat {
  padding-bottom: 20px;
  &.need_padding_bottom {
  }
}
.asc_pat {
  margin: 1rem 0.5rem;
  margin-bottom: 0;
  // padding-bottom: 20px;

  @media (min-width: 1000px) {
    width: unset;
  }

  .infos-prebottom {
    text-align: center;
    padding: 0.25rem;
    font-size: 16px;
  }
  .infos-bottom {
    font-style: italic;
    text-align: center;
  }

  &.notif_on {
    margin-bottom: calc(66px + 1rem);
  }
}
