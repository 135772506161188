.modal_reinit {
  display: block;
  position: fixed;
  top: calc(50vh - 86px);
  left: calc(50vw - 175px);
  background-color: white;
  height: fit-content;
  width: 350px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal-content {
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;

    .errorMessage {
      padding: 0 10px;
      text-align: center;
      color: #cc0000;
      padding-bottom: 0.75rem;
    }
    .inputs_reinit {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      // max-height: calc(calc(var(--vh) * 96) - 119px);
      // height: 114px;
      overflow: auto;
    }
    input {
      width: 320px;
      font-size: 17px;
      padding: 15px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      border-radius: 3px;
      border: solid 1px #d2d2d2;
      width: 100%;
    }

    .erreur_input {
      border: solid 1px red;
    }

    .div_btn_reinit {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 0.75rem;
    }
  }
}
