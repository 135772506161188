.filter_custom_wrapper {
  padding-bottom: 0.5rem;

  &.fromCustom {
    @media (min-width: 770px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1450px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .card_custom_filter {
    margin: 0.75rem;
    min-height: 98px;
    background-color: #f2f3fb;
    border-radius: 5px;
    font-size: 14px;

    &.locked {
      background-color: lightgrey;
    }

    .title_div_filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      h3 {
        margin: 1rem;
        font-size: 16px;
      }
      .icon_remove {
        color: red;
        padding: 1rem;
        border-radius: 50%;
      }
    }

    input {
      background-color: #f2f3fb;
    }

    .input_type1 {
      border: none;
      border-bottom: 1px solid grey;
      outline: none;
      width: 80%;
      margin-left: 1.5rem;
      margin-bottom: 1.25rem;
      font-size: 14px;
      user-select: none;
      padding: 0.5rem 0;

      &.locked {
        background-color: lightgrey;
        user-select: none;
      }

      @media (min-width: 770px) {
        cursor: pointer;

        &.locked {
          cursor: unset;
        }
      }
    }

    .input_type2 {
      border: none;
      border-bottom: 1px solid grey;
      outline: none;
      width: 80%;
      margin-left: 1.5rem;
      margin-bottom: 1.25rem;
      font-size: 14px;
      user-select: none;
      padding: 0.5rem 0;

      &.locked {
        background-color: lightgrey;
        user-select: none;
      }
    }

    .input_type3 {
      margin-bottom: 1rem;
      label {
        margin-left: 1.25rem;
      }
      input {
        border: none;
        border-bottom: 1px solid grey;
        outline: none;
        width: 4rem;
        margin: 0 0.25rem;
        font-size: 14px;
        text-align: center;
        padding: 0.5rem 0;

        &.locked {
          background-color: lightgrey;
          user-select: none;
        }
      }
    }
    .input_type4 {
      margin-bottom: 1rem;
      .line_date {
        margin-left: 1.25rem;

        label {
          width: 25px;
          display: inline-block;
        }

        @media (min-width: 360px) {
          display: inline-block;

          &.line2 {
            margin-left: 0;
            label {
              margin-left: 0.25rem;
              margin-right: 0.25rem;
            }
          }
        }
        @media (min-width: 450px) {
          &.line2 {
            margin-left: 0;
            label {
              margin-left: 0.5rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
      input {
        border: none;
        border-bottom: 1px solid grey;
        outline: none;
        width: 6.25rem;
        margin: 0 0.25rem;
        font-size: 14px;
        text-align: center;
        padding: 0.5rem 0;
        height: 35px;

        @media (min-width: 400px) {
          width: 8.25rem;
        }

        &.locked {
          background-color: lightgrey;
          user-select: none;
        }
      }

      input::-webkit-date-and-time-value {
        margin-right: 0;
      }
    }
  }

  &.fromSideInv {
    .card_custom_filter {
      width: 376px;
    }
    .card_custom_filter:first-child {
      margin-top: 0;
    }
  }
}
