.modal_exports_op {
  display: block;
  position: fixed;
  top: calc(50vh - 71px);
  left: calc(50vw - 128px);
  min-width: 340px;
  width: 340px;
  background-color: white;
  z-index: 12;
  color: #000;
  border-radius: 8px;

  .message_alert_rejet {
    padding: 7px 0;
    color: #cc0000;
  }

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: center;
    }
  }

  .modal_content {
    min-height: calc(calc(var(--vh) * 92) - 130px);
    max-height: calc(calc(var(--vh) * 92) - 130px);
    padding: 0 4px;
    max-width: 98vw;
    overflow-y: auto;

    .explication_exports {
      padding: 0 1rem 0.25rem 1rem;
      text-align: center;

      p {
        font-style: italic;
      }
    }

    textarea {
      width: 100%;
      padding: 0;
      min-height: calc(calc(var(--vh) * 92) - 162px);
      max-height: calc(calc(var(--vh) * 92) - 162px);
    }
  }

  .div_bottom {
    padding: 1rem;
    .div_button {
      display: flex;
      align-items: center;
      justify-content: center;

      .button_validate {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        background: #1a73e8;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-weight: 700;
        outline: 3px solid #1a73e8;
        padding: 0.75rem 40px;
      }
    }
  }
}
