.outilsOP {
  height: calc(calc(var(--vh) * 100) - 60px);
  margin-top: 60px;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  margin-bottom: 0.5rem;
  overflow: auto;

  .div_btns_outils_op {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-around;
    margin-left: 2rem;
    justify-content: left;
    gap: 1.25rem;
  }
}
