.modal_evalim {
  display: block;
  position: fixed;
  top: calc(50vh - 106px);
  left: 1vw;
  background-color: white;
  width: 98vw;
  max-width: 420px;
  z-index: 12;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal-content {
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;

    table {
      width: 100%;

      thead {
        th {
          vertical-align: middle;
          background-color: #45474e;
          color: #fff;
          padding: 6px 9px;
          text-align: center;
          white-space: nowrap;
          font-weight: 100;
        }
        th:first-child {
          border-radius: 5px 0px 0 0;
        }
        th:last-child {
          border-radius: 0px 5px 0 0;
        }
      }
      tbody {
        td {
          padding: 6px 9px;
          padding: 6px 9px;
          background-color: #f2f3fb;
          text-align: center;
        }
        tr:last-child {
          td:first-child {
            border-radius: 0 0px 0px 5px;
          }
          td:last-child {
            border-radius: 0 0px 5px 0px;
          }
        }
      }
    }

    .result_evalim_baseref {
      text-align: end;
    }
  }
}
