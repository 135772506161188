.custom_inv_name {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1rem 0;
  text-align: center;
  padding-bottom: 0;
}

.wrapper_list_columns {
  overflow: hidden;
  margin-top: 1rem;
  margin: 0 10px;
  border-radius: 15px;
  background-color: #eaedf9;
  overflow: auto;
  max-height: calc(calc(var(--vh) * 100) - 242px);
  padding: 10px;

  @media (min-width: 430px) {
    max-height: calc(calc(var(--vh) * 100) - 226px);
  }

  .list_container {
    display: flex;
    font-size: 18px;
    flex-direction: column;

    .item_container {
      background-position: 50%;
      display: flex;
      height: 52px;
      justify-content: space-between;
      padding: 2px 1rem 2px 2px;
      transition: background 0s;
      border-bottom: solid 1px #f7f7f7;

      .left_side_inv_cust {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon_grab {
          font-size: 20px;
          color: #838383;
        }
      }

      &.animate {
        background: rgba(18, 65, 134, 0.25) !important;
        transition: background 1s;
      }
      &:active {
        background: rgba(18, 65, 134, 0.25) !important;
      }

      &.locked {
        padding-left: 36px;
      }

      .col_name {
        display: flex;
        align-items: center;
      }

      .icons {
        display: flex;

        span {
          padding: 12px;
          border: none;
          color: #45474e;
          font-size: 22px;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          border-radius: 50%;

          @media (min-width: 770px) {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.div_btn_add_col {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  button {
    outline: none;
    font-size: 1em;
    text-transform: uppercase;
    padding: 1em 2em;
    background: #52ae32;
    color: #fff;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    text-shadow: none;
    transition-property: background, color, border;
    transition-duration: 0.218s;
  }
}

.modal_addcol {
  position: fixed;
  top: 2vh;
  bottom: 2vh;
  z-index: 12;
  max-width: 400px;
  width: 96vw;
  background: white;
  color: #45474e;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-size: 16px;

  .div_header_filter {
    text-align: center;
    .titre_filtrer {
      padding: 1rem 0;
      font-size: 1.17em;
      font-weight: bold;
    }
  }

  .modal_content {
    height: 100%;

    .div_input_add {
      width: 100%;
      display: flex;
      justify-content: center;

      .input_add {
        border: none;
        width: 9.25rem;
        outline: 0;
        color: black;
        height: 32px;
        font-size: 1rem;
        width: 80%;
        border-radius: 3px;
        font-weight: bold;
        border-bottom: 1px solid black;
      }
    }

    .wrapper_list_columns_modal {
      margin-top: 1rem;
      height: calc(calc(var(--vh) * 96) - 174px);
      overflow: auto;

      .cat_columns {
        margin: 0.5rem 0;
      }

      .line_col {
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;

        @media (min-width: 770px) {
          cursor: pointer;
        }

        .left_side_line_col {
          display: flex;
          align-items: center;

          &.greyedout {
            opacity: 0.7;
          }

          .cocheConfirmable {
            width: 20px;
            height: 20px;

            transition: background 0.15s ease;
            padding: 15px 18px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
              50% 50% no-repeat;

            &.on {
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
                50% 50% no-repeat;
            }
          }
        }

        .col_hbl {
          height: 25px;
          position: absolute;
          right: 0px;
        }
      }
    }
  }

  .div_close_modal_sort {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    border: none;
    padding: 1rem;
    gap: 1rem;

    .validate_btn_add {
      background: #1a73e8;
      outline: 3px solid #1a73e8;
      border-radius: 5px;
      border: #ffffff 1px solid;
      // text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      padding: 0.75rem 40px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    .cancel_btn_add {
      background: #ffffff;
      border-radius: 5px;
      border: #dcdee2 1px solid;
      color: #2178e9;
      padding: 0.75rem 26px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}

.modal_settings {
  position: fixed;
  z-index: 12;
  max-width: 400px;
  width: 96vw;
  background: white;
  color: #45474e;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-size: 16px;

  .div_header_filter {
    text-align: center;
    .titre_filtrer {
      padding: 1rem 0;
      font-size: 1.17em;
      font-weight: bold;
    }
  }

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      z-index: 14;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal_content {
    height: 100%;

    .wrapper_list_columns_modal {
      height: calc(calc(var(--vh) * 100) - 4vh - 148px);
      overflow: auto;

      .div_input_name {
        padding: 0.5rem;
        .input_name {
          border: none;
          outline: 0;
          color: #45474e;
          height: 32px;
          font-size: 1rem;
          width: inherit;
          min-width: 20vw;
          border-radius: 3px;
          font-weight: bold;
          border-bottom: 1px solid black;

          &.error {
            border-color: red;
          }
          &.error::placeholder {
            color: red;
          }
        }
        .erreur_message {
          margin-top: 0.25rem;
          font-size: 14px;
          color: red;
        }
      }

      .cat_columns {
        margin: 0.5rem 0;
      }

      .line_col {
        display: flex;
        height: 30px;
        align-items: center;

        @media (min-width: 770px) {
          cursor: pointer;
        }

        .left_side_line_col {
          display: flex;
          align-items: center;

          &.greyedout {
            opacity: 0.7;
          }
        }

        .cocheConfirmable {
          width: 20px;
          height: 20px;

          transition: background 0.15s ease;
          padding: 15px 18px;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
            50% 50% no-repeat;

          &.on {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
              50% 50% no-repeat;
          }
        }

        .col_hbl {
          height: 25px;
          position: absolute;
          right: 6px;
        }
      }
    }
  }

  .div_close_modal_sort {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    border: none;
    padding-bottom: 1rem;
    padding-right: 1rem;

    .close_modal_sort {
      background: #1a73e8;
      outline: 3px solid #1a73e8;
      border-radius: 5px;
      border: #ffffff 1px solid;
      color: #ffffff;
      font-weight: bold;
      padding: 0.75rem 40px;

      -webkit-tap-highlight-color: transparent;
    }
  }
}
