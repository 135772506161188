.confirm_window {
  display: block;
  position: fixed;
  background-color: #ffffff;
  border: 1px solid #e2e4e2;
  border-radius: 8px;
  z-index: 999;
  padding: 1rem;
  max-width: 98vw;
  top: calc(50vh - 78px); // Done for the one on datePicker choice
  left: 0; // Done for the one on datePicker choice

  .alert {
    padding: 1rem 0.25rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #45474e;
    text-align: center;
  }

  .div_btn_alert {
    display: flex;
    justify-content: flex-end;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    gap: 1rem;

    .btn_ok_alert {
      background: #1a73e8;
      outline: 3px solid #1a73e8;
      border-radius: 5px;
      border: #ffffff 1px solid;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      padding: 0.75rem 40px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    .btn_cancel_alert {
      background: #ffffff;
      border-radius: 5px;
      border: #dcdee2 1px solid;
      color: #2178e9;
      padding: 0.75rem 26px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
