.wrapper_button_add {
  font-size: 1rem;
  padding: 1rem 0;
  text-align: center;
}

.modal_addinv {
  position: fixed;
  top: calc(50vh - 83px);
  left: calc(50vw - 155px);
  z-index: 12;
  width: 75vw;
  background: white;
  color: #45474e;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-size: 16px;
  max-width: 320px;

  // @media (max-width: 500px) {
  //   top: calc(25vh - 83px);
  // }

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      z-index: 14;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal_content {
    padding: 0.75rem;
    padding-top: 0;
    width: 100%;
    overflow: auto;

    .wrapper_button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .input_add {
        border: none;
        width: 9.25rem;
        outline: 0;
        color: black;
        height: 32px;
        font-size: 1rem;
        width: inherit;
        border-radius: 3px;
        font-weight: bold;
        border-bottom: 1px solid black;

        &.error {
          border-color: red;
        }
        &.error::placeholder {
          color: red;
        }
      }

      .erreur_message {
        font-size: 14px;
        color: red;
      }
    }
  }
}

.wrapper_list_inv {
  margin-top: 1rem;
  margin: 0 10px;
  border-radius: 15px;
  background-color: #eaedf9;
  overflow: auto;
  max-height: calc(calc(var(--vh) * 100) - 132px);
  padding: 10px;
  user-select: none;

  .list_container {
    display: flex;
    font-size: 18px;
    flex-direction: column;

    .item_container {
      background-position: 50%;
      display: flex;
      height: 52px;
      justify-content: space-between;
      padding: 2px 1rem 2px 2px;
      transition: background 0s;
      border-bottom: solid 1px #f7f7f7;

      .left_side_inv_cust {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon_grab {
          font-size: 20px;
          color: #838383;
        }
      }

      &.animate {
        background: rgba(18, 65, 134, 0.25) !important;
        transition: background 1s;
      }
      &:active {
        background: rgba(18, 65, 134, 0.25) !important;
      }

      .inv_name {
        display: flex;
        align-items: center;
      }

      .icons {
        display: flex;

        span {
          padding: 12px;
          border: none;
          color: #45474e;
          font-size: 22px;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          border-radius: 50%;

          @media (min-width: 770px) {
            cursor: pointer;
          }
        }
      }
    }
  }
}
