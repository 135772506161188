.custom_inv {
  user-select: none;
  .nowrap {
    white-space: nowrap;
  }

  .span-date {
    display: block;
    font-size: 10px;
    font-style: italic;
    text-align: center;
    padding-top: 5px;
    min-width: 4.75rem;
  }

  .prelev_attente {
    display: inline-block;
    text-align: center;
    padding: 5px;
    background: #b61b15;
    color: #fff;
    white-space: nowrap;
  }

  .span-center {
    display: block;
    text-align: center;
  }

  .porteur1p {
    display: inline-block;
    text-align: center;
    padding: 5px;
    background: #b61b15;
    color: #fff;
  }

  .porteur2p {
    padding: 2px;
    background: #b61b15;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .filiation {
    text-decoration: underline;
  }

  .button-result {
    text-decoration: underline;
    background: none;
    border: 0px;
  }

  .cocheConfirmable {
    width: 20px;
    height: 20px;
    margin: auto;
    transition: background 0.15s ease;
    padding: 0 18px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
      50% 50% no-repeat;

    &.on {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
        50% 50% no-repeat;
    }
  }

  .first_child_choice_table {
    z-index: 4 !important;
    @media (min-width: 770px) {
      cursor: pointer;
    }
  }

  .erreurNonConfirmable {
    width: 20px;
    height: 20px;
    transition: background 0.15s ease;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACK0lEQVQ4T6WTTUhUURiGn3NHnX5IiCzNVBiF0ILwZ3SmPzJzli2kAoVyUeEm1IQWUajXkaRFYGptpFpYkFDhoqVmRi1mxhmVoJRAB9RMywiMfkade+KeGSUzQeps7nf53vc57/ddruA/j1jL720ssMWI8JjZX5SWdEetL/g37ZoAT13eYJarJNs0DXd3DTndgZx1A7wNBZXxiamtmaVVyjPS2crczESVo97X9idkVQK/nrdJWqzf7GcvE5yYVXpbagL+e9cR4dBmux74/jtkFcCMnl54Inv7vjRO1niU9nGzk0+vxxnre7JqlBUAT729dEtSxsM9p6th+BKnWnIV4FH1AGTd4O2DFr5Oj5Y5G/ydSylWALzuQ9JecQ1tshlCwxy/WaR0Ty/2gjULI6UGf/tVHHWvln3Lhac2tzutqLx4Z1IQZl/CBo1CXX0E+vQh+GlAwmE+TNsY7+3ocTYOuMyeAvTr+cUbE3d37y05BsF2ELFghY+f4xVgx7Y5CAFyAWwVvOl6xo+Zd658vb9HAbzuIzKn/DyxX+6CjAGhgdWgpClPAbquBCCkgTRALLKw9RyDHXdw1L0Qwqfvv5XsPHphV8YIzJvXaJFccQaFlSmREdomYd4EmG8GxFl5P5rJlOf5bRFoOihzzyRHlmpEV2I+LECMcsCigLA5QnT3WqQYuD+F8LkPSCMskYaMCpYgEi1ami3k0r6lSig0gWYRKmxk0H88a/5M6+X9AhpJum1Mrv6SAAAAAElFTkSuQmCC)
      50% 50% no-repeat;
    // cursor: pointer;
    padding: 0 25px;
    margin: auto;

    .listeErreurs {
      display: none;
      cursor: default;
    }
  }

  .inscription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;

    span {
      font-size: 16px;
    }
  }
}

.footer_to_confirm {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eaedf9;
  color: #45474e;
  padding: 0.5rem 0;

  .div_text_footer_confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      font-size: 16px;
    }
  }

  .div_btn_footer_confirm {
    padding-right: 0.5rem;

    button {
      outline: none;
      font-size: 1em;
      padding: 1rem 0.7rem;
      background: #44b00c;
      color: #fff;
      border: 0;
      border-radius: 3px;
      text-decoration: none;
      text-shadow: none;
      text-transform: uppercase;
      margin: 0;
      width: 100px;
      @media (min-width: 770px) {
        cursor: pointer;
      }
    }
  }

  &.zindex10 {
    z-index: 10;
  }
  &.zindex9 {
    z-index: 9;
  }
}
