.modal_results_confirm {
  display: block;
  position: fixed;
  background-color: white;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 85vh;
  width: 98vw;
  max-width: 400px;
  left: 1vw;
  border-radius: 8px;

  .div_modal_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    padding: 0 56px;

    .close_modal {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .print_conf {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
      top: 0;
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .modal_content {
    width: 100%;
    overflow: auto;

    .confirm_done {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      padding-left: 1rem;

      &.valid span {
        color: #66cc00;
      }
      &.rejected span {
        color: red;
      }

      .num_ani {
        display: flex;
        align-items: center;
        font-size: 1rem;
        white-space: nowrap;
      }
      .result_div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;

        p {
          padding: 10px;
          width: 90px;
          text-align: center;
          color: #fff;
          font-size: 13px;
          height: 33px;

          &.rej {
            background-color: #cc0000;
          }
          &.conf {
            background-color: #30dd34;
          }
        }
      }

      .btn_certificat {
        outline: none;
        font-size: 11px;
        text-transform: uppercase;
        background: #52ae32;
        color: #fff;
        border: 0;
        border-radius: 3px;
        text-decoration: none;
        text-shadow: none;
        padding: 10px;
        height: 33px;
      }
    }
    ul {
      margin-left: 3rem;
      margin-top: -8px;
      list-style: unset;
      list-style-type: disc;
      li {
        list-style-type: disc;
      }
    }

    .footer_btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      gap: 1rem;

      .btn_all_certificate {
        outline: none;
        font-size: 1em;
        padding: 1rem 0.7rem;
        background: #44b00c;
        color: #fff;
        border: 0;
        border-radius: 3px;
        text-decoration: none;
        text-shadow: none;
        text-transform: uppercase;
        margin: 0;
      }
    }
  }
}
