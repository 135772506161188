.analyses_fiche_station {
  .analyse_genetique {
    width: 100%;
    overflow: auto;

    table {
      width: 100%;
      font-size: 13px;
      border-bottom: solid 1px #ccc;

      thead {
        background: #f4f4f4;
        color: #333;
        th {
          padding: 6px 9px;
          font-weight: normal;
        }
      }
      tbody {
        td {
          padding: 6px 9px;
          text-align: center;

          .prelev_ok {
            padding: 5px 7px;
            background-color: #5bbd38;
            color: #fff;
            display: inline-block;
          }

          .prelev_attente {
            display: inline-block;
            text-align: center;
            padding: 5px;
            background: #b61b15;
            color: #fff;
          }
        }
      }
    }
  }

  .alert {
    padding: 0.75rem;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    color: #9c9c9c;
  }
}
