.modal_loader {
  position: fixed;
  top: calc(50vh - 36px);
  left: 0;
  max-width: 80vw;
  background-color: white;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  max-height: 85vh;
  z-index: 611;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  min-width: 320px;
  width: fit-content;
  max-width: 89vw;
  padding: 1rem;
  font-size: 1.15rem;
  text-align: center;
}

.button_modal_loader {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh) * 100);
  width: 100vw;
  z-index: 610;
  opacity: 40%;
  background: black;
  border: none;
}
