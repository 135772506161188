.selectdata {
  overflow-y: hidden;
  position: fixed;
  height: 60px;
  top: 60px;
  left: 0;
  width: 100vw;
  background: white;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  &.with_side_filters {
    right: 416px;
    width: calc(100vw - 416px);
  }

  .ul_select {
    list-style-type: none;
    display: flex;
    white-space: nowrap;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    user-select: none;

    .li_select {
      display: inline;
      padding: 22px 15px;
      border-bottom: solid 1px #eaedf9;
      color: #45474e;
      font-size: 16px;
      font-weight: bold;

      @media (min-width: 770px) {
        cursor: pointer;
      }
    }

    .li_active {
      color: #2a5abc;
    }
  }

  .scroll_select {
    width: 100%;
    height: 3px;

    .active_border {
      background: #2a5abc;
      height: 3px;
      margin-left: 0;
      transition: all 0.25s ease-in-out;
      margin-top: -4px;
      border-radius: 15px 15px 0 0;
      position: absolute;
    }
  }
}
.noscroll::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}
.noscroll::-webkit-scrollbar-track {
  display: none;
  -webkit-appearance: none;
}
.noscroll::-webkit-scrollbar-thumb {
  display: none;
  -webkit-appearance: none;
}
.scroll_hover::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}
.scroll_hover::-webkit-scrollbar-track {
  display: none;
  -webkit-appearance: none;
}
.scroll_hover::-webkit-scrollbar-thumb {
  display: none;
  -webkit-appearance: none;
}
.scroll_hover:hover::-webkit-scrollbar {
  display: unset;
  -webkit-appearance: unset;
}
.scroll_hover:hover::-webkit-scrollbar-track {
  display: unset;
  -webkit-appearance: unset;
}
.scroll_hover:hover::-webkit-scrollbar-thumb {
  display: unset;
  -webkit-appearance: unset;
}
