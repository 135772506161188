.page_visites {
  height: calc(calc(var(--vh) * 100) - 60px);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  margin-bottom: 0.5rem;
  overflow: auto;

  .liste_visites {
    @media (min-width: 770px) {
      padding: 0.5rem;
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1450px) {
      grid-template-columns: repeat(4, 1fr);
    }

    .card_visit {
      background-color: #f2f3fb;
      padding: 12px;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      position: relative;
      min-height: 128px;
      border-radius: 5px;
      margin: 1rem;

      @media (min-width: 770px) {
        margin: 0;
      }

      .date_visite {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .div_activity {
        min-height: 72px;

        ul {
          // display: grid;
          height: fit-content;
          grid-template-columns: 1fr 1fr;
          width: 100%;
        }

        .nom_activite {
          display: inline-block;
          padding: 3px 10px;
          border-radius: 50px;
          margin-bottom: 0.25rem;
          margin-right: 10px;
          max-width: fit-content;
          font-size: 13px;
          color: #fff;

          &.conseil {
            background-color: #ab47bc;
          }
          &.certif {
            background-color: #e57373;
          }
          &.qualif {
            background-color: #9575cd;
          }
          &.ptge_A {
            background-color: #ffb74d;
          }
          &.ptge_S {
            background-color: #64b5f6;
          }
          &.tri_hbl {
            background-color: #a1887f;
          }
          &.dgloc {
            background-color: #66bb6a;
          }
          &.pointage_mamelle {
            background-color: #f06292;
          }
          &.ouverture_pelvienne {
            background-color: #00bcd4;
          }
          &.sabots_docilites {
            background-color: #7cb342;
          }
          &.mh_filiat {
            background-color: #ffa726;
          }
          &.SECi_point_sortie {
            background-color: #607d8b;
          }
          &.formation_pointage {
            background-color: #616161;
          }
        }

        .a_venir {
          // vertical-align: middle;
          // text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 22px;
          color: #9c9c9c;
          min-height: inherit;
          height: inherit;
        }
      }

      .name_pointeur {
        text-align: right;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }

  .modal_filter_visite {
    position: fixed;
    top: calc(50vh - 100px);
    left: 10vw;
    z-index: 18;
    background: white;
    box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
    border-radius: 8px;
    font-size: 16px;

    .div_choices_input {
      padding-top: 0.75rem;
      max-height: calc(var(--vh) * 88);
      overflow: auto;

      .div_line_val {
        display: flex;
        align-items: center;

        .cocheConfirmable {
          width: 20px;
          height: 20px;

          transition: background 0.15s ease;
          padding: 15px 18px;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
            50% 50% no-repeat;

          &.on {
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
              50% 50% no-repeat;
          }
        }

        p {
          margin: 0;
          padding: 0.75rem 1rem;
          width: 320px;
        }
      }
    }

    .div_button_input_choices {
      display: flex;
      justify-content: end;
      margin-top: 0.75rem;

      button {
        background: none;
        outline: none;
        border: none;
        text-transform: uppercase;
        color: blue;
        padding: 1rem 1.5rem;
      }
    }
  }
}
