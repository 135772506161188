.modal_changelog {
  position: fixed;
  background-color: white;
  z-index: 15;
  max-height: calc(var(--vh) * 90);
  max-width: 90vw;
  width: 600px;
  padding-bottom: 12px;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  top: 0;
  left: 0;

  .div_header_version {
    display: flex;

    .close_modal_version {
      padding: 12px;
      border-radius: 50%;
      padding-bottom: 12px;
      font-size: 32px;
      position: absolute;
      right: 0;
    }

    .div_content_version {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 12px 0;
      gap: 0.5rem;

      h3 {
        display: block;
        font-size: 1.17rem;
        font-weight: bold;
        margin: 0;
      }
      p {
        margin: 0;
        font-size: 1rem;

        &.version_number {
          border-radius: 10px;
          padding: 3px 11px;
          color: white;
        }

        &.red_text {
          background-color: #cc0000;
        }
        &.green_text {
          background-color: #009900;
        }
      }
    }
  }
  .div_body_version {
    padding: 0 0.5rem;
    max-height: calc(calc(var(--vh) * 90) - 110px);
    overflow: auto;

    .div_log_version {
      h3 {
        display: block;
        font-size: 1.17em;
        font-weight: bold;
        margin: 0;
      }

      ul {
        margin: 0.5rem;
        padding-left: 20px;
        list-style: unset;
        font-size: 1rem;

        li {
          margin-bottom: 0.25rem;
        }
      }
    }
  }
  .div_regles_conf {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin-top: 0.25rem;
    padding: 1rem 0.25rem;
    text-decoration: underline;
    width: 100%;
  }
}
