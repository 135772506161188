.modal_select_vals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 18;
  background: white;
  box-shadow: 3px 5px 8px 2px rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-size: 16px;

  .div_choices_input {
    padding-top: 0.75rem;
    max-height: calc(var(--vh) * 88);
    overflow: auto;

    .div_line_val {
      display: flex;
      align-items: center;

      .cocheConfirmable {
        width: 20px;
        height: 20px;

        transition: background 0.15s ease;
        padding: 15px 18px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAfklEQVQ4T2NkoDJgBJlXUlISyMjIOIOBgUGMTPNf////P7Wnp2cj2MDS0tKXFBgGc8Or7u5ucZiB/0GiXV1dZDmwrKwMrK+7u5tx1MDRMCQyEY0mG3BeHoFZ7xUDA4MokakEl7KX3d3dErAC1p+RkXEWBWXiS2gBuxlsIDUBANDofhVbzG5qAAAAAElFTkSuQmCC)
          50% 50% no-repeat;

        &.on {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABFklEQVQ4T2NkoDJgBJmnecEy8P9/hhmMDAxiZJr/moGBIe264fENYAM1zlu+pMAwsBv+MzC8umF4XBziwvOW/8l0GYq264bHGSkyUI1DmeHWj7twQyky0EfQnaFdrpZhxsv5DFNfzAUbSraBMMOYGZnAhlFkIC7DcLoQPVyQQx2fYVgNzJFIYUgXT2Aof9TAsO39HpQYJGQYVgOzJZIZQPjv/38MFY8aGba+3w02lBjDcHoZ2dDKR00MDAyM4NhEjwBs6RZnLCMbCtJIjGEEkw3MUJBC5KSBL0cRTIcgQ2EGEpM1CRpIjCHIamhq4CsGBgZRUl2Epv7ldcPjErDSJuA/A8NMCsrEl0z//6ddNTqxCWwgNQEA9dKrFXlrIY4AAAAASUVORK5CYII=)
            50% 50% no-repeat;
        }
      }

      p {
        margin: 0;
        padding: 0.75rem 1rem;
        width: 320px;
      }
    }
  }

  .div_button_input_choices {
    display: flex;
    justify-content: end;
    padding: 1rem;

    button {
      background: #1a73e8;
      outline: 3px solid #1a73e8;
      border-radius: 5px;
      border: #ffffff 1px solid;
      // text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      padding: 0.75rem 40px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }
}
