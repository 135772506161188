// Colors variables
$primary: #5d1502; // blue
$success: #34a853; // green
$danger: #ea4335; // red
$warning: #8d2a11; // yellow

// Container and shapes dimentions
$containerDimentions: 30px;
$shapeDimentions: 10px;
//shape translate amount
$amount: 5px;

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &.horizontal {
    flex-direction: row;
  }

  .container {
    position: relative;
    width: $containerDimentions;
    height: $containerDimentions;
    border-radius: $containerDimentions/2;
    animation: rotation 1s infinite;
    .shape {
      position: absolute;
      width: $shapeDimentions;
      height: $shapeDimentions;
      border-radius: $shapeDimentions/2;
      &.shape-1 {
        left: 0;
        background-color: $primary;
      }
      &.shape-2 {
        right: 0;
        background-color: $success;
        animation-delay: 0.3s;
      }
      &.shape-3 {
        bottom: 0;
        background-color: $danger;
        animation-delay: 0.3s;
      }
      &.shape-4 {
        bottom: 0;
        right: 0;
        background-color: $warning;
      }
    }
    @for $i from 1 through 4 {
      .shape-#{$i} {
        animation: shape#{$i} 0.3s infinite alternate;
      }
    }
  }
  // .loader-box {
  //   height: 40px;
  //   width: 40px;
  //   perspective: 1000px;
  //   .loader-thing {
  //     height: 20px;
  //     width: 20px;
  //     background-color: #52ae32;
  //     position: absolute;
  //     box-sizing: border-box;
  //     top: 0;
  //     left: 0;
  //   }
  //   .loader-thing:nth-of-type(1) {
  //     animation: bounce 0.5s ease-in-out infinite alternate,
  //       move 4s -1s infinite;
  //   }
  //   .loader-thing:nth-of-type(2) {
  //     animation: bounce 0.5s ease-in-out infinite alternate,
  //       move 4s -2s infinite;
  //   }
  //   .loader-thing:nth-of-type(3) {
  //     animation: bounce 0.5s ease-in-out infinite alternate,
  //       move 4s -3s infinite;
  //   }
  //   .loader-thing:nth-of-type(4) {
  //     animation: bounce 0.5s ease-in-out infinite alternate,
  //       move 4s -4s infinite;
  //   }
  // }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin shapeTranslate($index, $tx, $ty) {
  @keyframes shape#{$index} {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate($tx, $ty);
    }
  }
}

@include shapeTranslate(1, $amount, $amount);
@include shapeTranslate(2, -$amount, $amount);
@include shapeTranslate(3, $amount, -$amount);
@include shapeTranslate(4, -$amount, -$amount);

// @keyframes bounce {
//   from {
//     transform: scale(1);
//   }

//   to {
//     transform: scale(0.8);
//   }
// }

// @keyframes move {
//   0% {
//     top: 0;
//     left: 0;
//     background-color: #52ae32;
//   }
//   25% {
//     top: 0;
//     left: 50%;
//     background-color: #b61b15;
//   }
//   50% {
//     top: 50%;
//     left: 50%;
//     background-color: #8d2a11;
//   }
//   75% {
//     top: 50%;
//     left: 0;
//     background-color: #5d1602;
//   }
// }
