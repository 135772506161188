.op_content {
  height: calc(calc(var(--vh) * 100) - 60px);
  margin-top: 60px;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  margin-bottom: 0.5rem;
  overflow: auto;

  .loader_liste {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(calc(var(--vh) * 100) - 120px);
    width: 100vw;
  }

  h2 {
    margin: 75px 12px;
    font-size: 1.5rem;
    text-align: center;
  }

  .liste_cheptels {
    @media (min-width: 770px) {
      padding: 0.5rem;
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1450px) {
      grid-template-columns: repeat(4, 1fr);
    }

    .chep_card {
      background-color: #f2f3fb;
      padding: 12px;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      position: relative;
      min-height: 128px;

      @media (min-width: 770px) {
        background-color: #f2f3fb;
        border-radius: 5px;
      }

      .first_line {
        display: flex;
        align-items: center;
        position: relative;
        .nom_cheptel {
          font-weight: bold;
        }
        .adherent {
          position: absolute;
          right: 5px;
          font-size: 13px;
          color: white;
          background-color: #124186;
          border-radius: 50px;
          padding: 3px 10px;
        }
      }

      .numchep {
        font-size: 12px;
        margin: 10px 0;
      }

      .text {
        font-size: 12px;
      }

      .in_app {
        position: absolute;
        top: calc(45% - 17px);
        right: 17px;
        color: #44b00c;
        border-radius: 8px;
        padding: 5px 6px 3px 8px;
        user-select: none;

        span {
          user-select: none;
        }
      }

      .btn_delete {
        outline: none;
        font-size: 1em;
        color: #cc0000;
        border-radius: 8px;
        padding: 5px 6px 3px 8px;
        position: absolute;
        bottom: 12px;
        right: 17px;
      }
    }
    .chep_card:nth-child(2n + 1) {
      background-color: #fff;

      @media (min-width: 770px) {
        background-color: #f2f3fb;
        border-radius: 5px;
      }
    }
  }
}
