.app {
  font-family: "roboto", Arial;
  font-size: 13px;
  color: #45474e;

  .div_statut_bar {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 0;
    background-color: #eaedf9;
    z-index: 1;
  }

  &.push {
    overflow-x: hidden;
  }
  .content {
    min-height: calc(calc(var(--vh) * 100) - 95px - 261px);
  }

  .loading_template {
    height: calc(calc(var(--vh) * 100) - 60px);
    margin-top: 60px;
    background-color: #eaedf9;
    padding-top: 2rem;
  }
}
